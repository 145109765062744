.courseClassHeader-openClass-father-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--color-secondary-dark);
  padding: 16px;
  border-radius: var(--border-radius-sm);
}

.courseClassHeader-openClass-text {
  color: var(--color-text-tertiary);
  font-size: var(--fs-body);
  line-height: var(--fs-body);
}

.courseClassHeader-openClass-button-div {
  display: flex;
  margin-left: auto;
  gap: 8px;
}

.courseClassHeader-openClass-button-span {
  padding: 4px 0 0 8px;
}

/* Courses Closed Card below */

.courseClassHeader-closedClass-father-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--color-primary-lightest);
  padding: 16px;
  border-radius: var(--border-radius-sm);
}

.courseClassHeader-closedClass-text {
  color: var(--color-text-primary);
  font-size: var(--fs-body);
}

.courseClassHeader-closedClass-button-div {
  margin-left: auto;
}

.courseClassHeader-closedClass-button-span {
  padding: 4px 0 0 8px;
}

/* Title styling */

.coursesClassHeader-classTitle-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.coursesClassHeader-title-styling {
  font-size: var(--fs-h2);
  color: var(--color-text-secondary);
}

/* Classes for custom hover code */
.courseClassHeader-hoverCard-textDescription{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-small);
  color: var(--color-text-tertiary);
  text-decoration: underline;
}
.courseClassHeader-hoverCard-father-div {
  display: flex;
}

.courseClassHeader-hoverCard-codeText {
  font-size: var(--fs-body);
  margin: 0 8px 0 0;
}

.courseClassHeader-hoverCard-copyIconStyling {
  cursor: pointer;
}

.courseClassHeader-hoverCard-copyIconStyling:hover {
  color: #A3D2EA;
}

@media screen and (max-width: 768px) {
  .courseClassHeader-openClass-father-div {
    flex-direction: column;
  }

  .courseClassHeader-openClass-button-div {
    margin: 8px 0 0 0;
  }

  .courseClassHeader-openClass-button-span {
    padding: 4px 0 0 8px;
  }

  .courseClassHeader-closedClass-button-div {
    margin-left: auto;
  }

  .courseClassHeader-closedClass-button-span {
    padding: 4px 0 0 8px;
  }
}

@media screen and (max-width: 480px) {
  .courseClassHeader-openClass-button-div {
    flex-direction: column;
    margin-left: 0;
  }
}
