.LoginPage-header-container{
   position: relative;
   display: grid;
   grid-template-columns: 150px 1fr 150px;
   width: 100%;
}

.LoginPage-header--group{
   display: grid;
   place-items: center;
}

.LoginPage-header--logo{
   height: 30px;
   width: auto;
   object-fit: cover;
}

.LoginMegaMenu-btn--active span{
   text-decoration: underline;
   color: var(--color-tertiary);
}

.LoginMegaMenu--chevronIcon{
   transition: all .3s;
}

.LoginMegaMenu-btn--active .LoginMegaMenu--chevronIcon{
   transform: rotateZ(180deg);
   transition: all .3s;
}

@media (max-width: 768px) {
   .LoginPage-header-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
   .LoginPage-header--logo{
      height: 30px;
   }
}