.forumNewDiscussion-father-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 180px;
}

.forumNewDiscussion-header-title{
    font-size: var(--fs-h2);
    margin: 16px 0;
    color: var(--color-text-secondary);
}

.forumNewDiscussion-content-div{
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 1px solid grey;
    border-radius: var(--border-radius-sm);
    gap: 16px;
}

.forumNewDiscussion-richTextEditor-root{
    width: 768px;
}

.forumNewDiscussion-richTextEditor-content{
    font-size: var(--fs-tiny) !important;
}

.forumNewDiscussion-button{
    margin: 8px;
}

@media (min-width:1000px) and (max-width: 1200px) {
    .forumNewDiscussion-richTextEditor-root{
        width: 600px;
    }
}

@media (min-width:768px) and (max-width: 1000px) {
    .forumNewDiscussion-father-div{
        padding: 16px;
    }

    .forumNewDiscussion-header-title{
        text-align: center;
    }

    .forumNewDiscussion-button{
        margin: 8px 0;
    }

    .forumNewDiscussion-richTextEditor-root{
        width: 100%;
    }
}

@media (max-width: 768px) {
    .forumNewDiscussion-father-div{
        padding: 16px;
    }

    .forumNewDiscussion-header-title{
        text-align: center;
    }

    .forumNewDiscussion-button{
        margin: 8px 0;
    }

    .forumNewDiscussion-richTextEditor-root{
        width: 100%;
    }
}