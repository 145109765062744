
.RichTextEditor--paragraph {
   font-size: var(--fs-body);
   white-space: pre-wrap;
   word-break: break-word;
   line-height: 1.7rem;
   background-color: inherit;
}

.tiptap h1.RichTextEditor--heading {
   font-size: var(--fs-h2);
}

.tiptap h2.RichTextEditor--heading {
   font-size: var(--fs-h3);
}

.tiptap h3.RichTextEditor--heading {
   font-size: var(--fs-h4);
}

.tiptap h4.RichTextEditor--heading {
   font-size: var(--fs-h5);
}

.tiptap img.RichTextEditor--image{
   max-height: 450px;
   max-width: 450px;
   width: auto;
   object-fit: cover;
}