.UserProfile-header-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   /* align-items: center; */
}

.UserProfile-header-text{
   font-family: var(--font-primary);
   font-size: var(--fs-h2);
   color: var(--color-text-secondary)
}