.imageOverShape-container {
    position: relative;
    aspect-ratio: 2/1;

    display: flex;
    justify-content: center;
}

.imageOverShape-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: var(--color-primary); */
}

.imageOverShape-image {
    position: absolute;
    bottom: 0;
    width: auto;
    object-fit: contain;
    filter: drop-shadow(8px 0 8px var(--color-black-transparent));
}

/* Clases para mantener los bordes del lado inferior rectos */
.imageOverShape-rectangle-bottom-1 {
    border-radius: 16px 48px 32px;
}
.imageOverShape-rectangle-bottom-2 {
    border-radius: 48px 8px 8px 48px;
}
.imageOverShape-rectangle-bottom-3 {
    border-radius: 16px 48px;
}
.imageOverShape-rectangle-bottom-4 {
    border-radius: 50% 50% 0 0;
}
.imageOverShape-rectangle-bottom-5 {
    border-radius: 96px 16px 0 0;
}
.imageOverShape-rectangle-bottom-6 {
    border-radius: 96px 16px 16px 96px;
}

/* Clases para mantener los bordes del lado izquierdo rectos */
.imageOverShape-rectangle-left-1 {
    border-radius: 0 48px 32px 0;
}
.imageOverShape-rectangle-left-2 {
    border-radius: 0 96px 48px 0;
}
.imageOverShape-rectangle-left-3 {
    border-radius: 0 48px 96px 0;
}
.imageOverShape-rectangle-left-4 {
    border-radius: 0 24px 96px 0;
}
.imageOverShape-rectangle-left-5 {
    border-radius: 0 32px 64px 0;
}
.imageOverShape-rectangle-left-6 {
    border-radius: 0 48px 48px 0;
}

/* Clases para mantener los bordes del lado derecho rectos */
.imageOverShape-rectangle-right-1 {
    border-radius: 32px 0 0 48px;
}
.imageOverShape-rectangle-right-2 {
    border-radius: 96px 0 0 48px;
}
.imageOverShape-rectangle-right-3 {
    border-radius: 48px 0 0 64px;
}
.imageOverShape-rectangle-right-4 {
    border-radius: 32px 0 0 64px;
}
.imageOverShape-rectangle-right-5 {
    border-radius: 64px 0 0 48px;
}
.imageOverShape-rectangle-right-6 {
    border-radius: 32px 0 0 32px;
}

.imageOverShape-rectangle-square{
   border-radius: 16px 16px 16px 16px;
}