.examScoreComponent-father-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 800px;
}

.examScoreComponent-title-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--fs-h3);
    color: var(--color-text-secondary);
}

.examScoreComponent-image-div {
    margin-top: 32px;
}

.examScoreComponent-image{
    max-height: 150px;
    height: 100%;
}

.examScoreComponent-percentage-div {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.examScoreComponent-yourNote-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-small);
}

.examScoreComponent-percentage-approved {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-light);
    color: var(--color-text-tertiary);
    padding: 16px;
    font-size: var(--fs-h3);
    border-radius: var(--border-radius-sm);
}

.examScoreComponent-percentage-failed {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-red--light);
    color: var(--color-text-tertiary);
    padding: 16px;
    font-size: var(--fs-h3);
    border-radius: var(--border-radius-sm);
}

.examScoreComponent-recoveryDate-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    gap: 8px;
}

.examScoreComponent-recoveryDate-header{
    font-size: var(--fs-h5);
    line-height: calc(var(--fs-h5)*1.4);
}

.examScoreComponent-recoveryDate{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.4);
}

.examScoreComponent-recoveryDate-span{
    font-weight: 700;
}

.examScoreComponent-buttons-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}

.examsScoreComponent-firstButton-root {
    margin-right: 16px;
}

@media (max-width: 768px) {
    .examScoreComponent-title-content {
        margin: 0 16px;
    }

    .examScoreComponent-buttons-div {
        flex-direction: column;
        margin-top: 32px;
    }

    .examsScoreComponent-firstButton-root {
        margin-right: 0;
        margin-bottom: 16px;
    }
}