/* Checkbox Terms And Conditions */
.termsAndConditions-legend-checkbox{
    font-size: var(--fs-tiny);
    color: var(--color-text-secondary);
 }
 
 .termsAndConditions-input-checkbox{
    margin: 0 8px 0 0;
 }

 .termsAndConditions-modal-opener{
    cursor: pointer;
    color: var(--color-secondary);
}
 /* End checkbox terms and conditions */



 /* Modal modifications */
.termsAndConditionsModal-text{
    display: flex;
    flex-direction: column;
    font-size: var(--fs-tiny);
}

.termsAndConditionsModal-title{
    font-size: var(--fs-h4) !important ;
}
/* End modal Modifications */