.surveyFormat-modalStyling{
    padding: 16px;
    background: linear-gradient(90deg, #e3edf2, #A3D2EA);
}
.surveyFormat-modalHeader{
    display: none !important;
}

.surveyFormat-surveyHeader{
    display: flex;
    justify-content: center;
    font-size: var(--fs-h4);
    line-height: calc(var(--fs-h4)*1.3);
    padding: 8px;
}


/* SurveyFormat Benefit modal styling */

.surveyFormat-benefitModal-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 16px;
}

.surveyFormat-benefitModal-header{
    font-size: var(--fs-h4);
    line-height: calc(var(--fs-h4)*1.3);
}

.surveyFormat-benefitModal--mainText{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
}

.surveyFormat-benefitModal--spanText{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
    font-weight: bold;
}

/* .surveyFormat-benefitModal-badgePositioning{
    position: relative;
} */

.surveyFormat-benefitModal-copyIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 0.3s;
    color: #5D5A6F;
}

.surveyFormat-benefitModal-copyIcon-copied {
    color: #DF3576;
}

.surveyFormat-benefitModal-benefitWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.surveyFormat-benefitModal-benefitBadge-wrapper{
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
}

.surveyFormat-benefitModal-benefitText{
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body)*1.3);
}

.surveyFormat-benefitModal-buttonsDiv{
    display: flex;
    gap: 32px;
}



/* SurveyFormat Benefit modal styling */
