.sidebar-nav-container {
   display: flex;
   flex-direction: column;
   gap: 16px;
}


@media (max-width: 768px) {
   .sidebar-nav-container{
      width: 100%;
   }
}