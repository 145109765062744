.OpportunitiesSkeletonCard-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.OpportunitiesSkeletonCard-flex-section{
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.OpportunitiesSkeletonCard-grid{
   width: 100%;
   display: grid;
   grid-template-columns: repeat(
      auto-fill, minmax(230px, 1fr)
   );
   gap: 20px;
}

.OpportunitiesSkeletonCard-article{
   background-color: #0000003b;
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 20px;
   justify-content: space-between;
   align-items: center;
   border-radius: var(--border-radius-md);
   box-shadow: var(--box-shadow);
   gap: 20px;
   height: 320px;
}

.OpportunitiesSkeletonCard--texts{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;
}