.ForoPage-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    width: 100%;

    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.ForoPage-section-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

}

@media screen and (max-width: 1100px) {
    .ForoPage-section-container {
        gap: 16px;
    }
}