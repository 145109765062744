.OwnCourses-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;

   /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
   overflow-y: scroll;
   height: 100%;
   padding: 16px 24px;
}

.OwnCourses-container .coursesClassHeaderNavigation-title-styling{
   font-size: var(--fs-h1);
   color: var(--color-text-secondary);
}

.OwnCoursesList-grid--group{
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.OwnCoursesList-grid-heading--text{
   font-size: var(--fs-h4);
   color: var(--color-text-primary);
}

@media (max-width < 1000px) {
   .OwnCourses-container .coursesClassHeaderNavigation-title-styling{
      font-size: var(--fs-h3);
   }
}