.coursesClassHeaderNavigation-button-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: var(--color-secondary-light);
    border-radius: var(--border-radius-sm);
    padding: 16px;
}

.coursesClassHeaderNavigation-button-span{
    color: var(--color-base);
    padding: 4px 8px 0 0;
}
