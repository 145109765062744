.validateCertificate-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  /*This is a must for every page layout without MainLayout on it*/
  max-width: var(--maxWidthContainer);
  box-shadow: var(--box-shadow-strong);
  /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
  height: 100%;
  padding: 16px 24px;
  background-image: url('../../assets/fondo-web-forvet-cropped.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.validateCertificate-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  color: var(--color-text-secondary);
}

.validatedCertificate-title {
  font-size: var(--fs-h2);
  margin-bottom: 24px;
  text-align: center;
}

.validatedCertificate-content-div {
  display: flex;
  width: 100%;
}

.validateCertificate-accordion {
  width: 70%;
  padding: 40px 16px 16px 16px;
}

.validateCertificate-accordion-header {
  font-size: var(--fs-h3);
  margin: 0 0 40px 0;
}

.validateCertificate-accordion-title {
  font-size: var(--fs-h4);
  color: var(--color-text-secondary);
}

.validateCertificate-accordion-root {
  padding: 0 16px 0 0;
}

.validateCertificate-accordion-content {
  font-size: var(--fs-tiny) !important;
}

.validatedCertificate-topics-div {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 40px;
}

.validatedCertificate-topics-titles {
  font-size: var(--fs-h4);
}

.validateCertificate-topics-content {
  font-size: var(--fs-small);
  margin-bottom: 16px;
}

.validateCertificate-forvetCertified-img {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  object-fit: contain;
  max-height: 180px;
  height: 100%;
}

.validateCertificate-footer-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
}

.validateCertificate-footer-img {
  background-color: var(--color-secondary-light);
  border-radius: var(--border-radius-sm);
  padding: 8px;
  opacity: 0.7;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .validateCertificate-layout {
    padding: 64px 80px;
    width: 100%;
  }
  .validateCertificate-accordion {
    width: 100%;
    padding: 40px 8px 8px 8px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .validateCertificate-layout {
    padding: 64px;
    width: 100%;
  }
  .validateCertificate-accordion {
    width: 100%;
    padding: 40px 8px 8px 8px;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .validateCertificate-layout {
    padding: 24px;
    width: 100%;
  }
  .validateCertificate-accordion {
    width: 100%;
    padding: 40px 8px 8px 8px;
  }
}

@media (max-width: 768px) {
  .validateCertificate-wrapper {
    padding: 8px;
  }
  .validateCertificate-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0;
    color: var(--color-text-secondary);
  }

  .validatedCertificate-content-div {
    flex-direction: column-reverse;
  }

  .validatedCertificate-topics-div {
    width: 100%;
    margin: 16px 40px 0 0;
  }

  .validateCertificate-accordion {
    width: 100%;
    padding: 8px;
  }
}
