.materials__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 24px;
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.materials__title {
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight: 700;
    color: var(--color-text-secondary);
    text-align: center;
    margin-bottom: 16px;
}
.materials__subtitle {
    font-size: var(--fs-h3);
    line-height: var(--fs-h3);
    font-weight: 300;
    color: var(--color-text-secondary);
    text-align: center;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .materials__container {
        padding: 24px;
    }

    .materials__title {
        margin-bottom: 16px;
    }
    .materials__subtitle {
        margin-bottom: 32px;
    }
}