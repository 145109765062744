.CalendarAside-container{
   position: relative;
   overflow-y: scroll;
   overflow-x: hidden;
   width: 100%;
   height: 100%;
   background-color: var(--color-base-dark);
   display: flex;
   flex-direction: column;
   border-radius: var(--border-radius-sm);
   padding: 16px 24px;
   gap: 24px;
   box-shadow: var(--box-shadow);
}

.CalendarAside-container::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
   overflow: -moz-scrollbars-none; /* Firefox */
}

.CalendarAside-header-container{
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   z-index: 1;
}

.CalendarAside-header--text{
   font-size: var(--fs-h3);
   line-height: var(--fs-h3);
   color: var(--color-text-secondary);
   font-weight: 600;
}

.calendar-back-icon {
   position: absolute;
   right: 20px; /* 24px - 4px padding inside icon */
   top: 12px; /* 16px - 4px padding inside icon */
   cursor: pointer;

   background-color: transparent;
   transition: var(--sidebar-transition);
}

.CalendarAside-content{
   z-index: 1;
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.CalendarAside-container.calendar-collapsed{
   padding: 6px;
}


.CalendarAside-container.calendar-collapsed .CalendarAside-content{
   align-items: center;
}

.CalendarAside-container.calendar-collapsed .CalendarAside-header-container{
   justify-content: center;
}

.CalendarAside-container.calendar-collapsed .CalendarAside-header--text{
   display: none;
}

.CalendarAside-container.calendar-collapsed .CalendarItem--month{
   text-align: center;
}

.CalendarAside-container.calendar-collapsed .CalendarRow-container{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.CalendarAside-container.calendar-collapsed .CalendarRow-separatorLine{
   width: 100%;
   height: 2px;
   order: 2;
}


.CalendarAside-container.calendar-collapsed .CalendarRow--eventText{
   font-size: var(--fs-tiny);
}

.CalendarAside-container.calendar-collapsed .CalendarRow--nameArea{
   display: none;
}


@media (min-width:768px) and (max-width: 1000px) {
   .CalendarAside-container{
      position: absolute;
      top: var(--headerHeight);
      left: 0;
      width: 100%;
      min-height: calc(100vh - var(--headerHeight));
      height: 100%;
      border-radius: 0;
      transition: var(--sidebar-transition);
      z-index: 100;
   }
   .CalendarAside-container.calendar-mobile--open{
      z-index: 100;
      left: 100%;
      background-color: var(--color-text-tertiary);
      transition: var(--sidebar-transition);
   }
   .CalendarAside-container.calendar-mobile--open .calendar-back-button{
      display: none;
   }

   .sidebar-closeCalendar-button{
      background-color: transparent;
      border: none;
      width: 60px;
      height: 60px;
   }

   .sidebar-closeCalendar-button--icon{
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   .CalendarAside-content{
      overflow-y: auto;
   }
}

@media (max-width: 768px) {
   .CalendarAside-container{
      position: absolute;
      top: var(--headerHeight);
      left: 0;
      width: 100%;
      min-height: calc(100vh - var(--headerHeight));
      height: 100%;
      border-radius: 0;
      transition: var(--sidebar-transition);
      z-index: 100;
   }
   .CalendarAside-container.calendar-mobile--open{
      z-index: 100;
      left: 100%;
      transition: var(--sidebar-transition);
      background-color: var(--color-text-tertiary);
   }
   .CalendarAside-container.calendar-mobile--open .calendar-back-button{
      display: none;
   }

   .sidebar-closeCalendar-button{
      background-color: transparent;
      border: none;
      width: 60px;
      height: 60px;
   }

   .sidebar-closeCalendar-button--icon{
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   .CalendarAside-content{
      overflow-y: auto;
   }
}