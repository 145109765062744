.sidebar-mobile-header-container{
   display: none;
}

@media screen and (max-width: 768px) {
   .sidebar-mobile-header-container{
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      height: var(--headerHeight);
      background-color: var(--color-primary-dark);
      padding-right: 10%;
      z-index: 100;
   }
   .sidebar-mobile-header-container .sidebar-logo-image{
      max-height: 50%;
   }

   .sidebar-mobile-icons-container{
      display: flex;
      align-items: center;
      gap: 20px;
   }

   .sidebar-mobile--calendarButton{
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
   }

   .sidebar-mobile--calendarIcon{
      width: 100%;
      height: 100%;
      cursor: pointer;
   }

}

@media (min-width: 768px) and (max-width: 1000px) {
   .sidebar-mobile-header-container{
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      height: var(--headerHeight);
      background-color: var(--color-primary-dark);
      padding-right: 10%;
      z-index: 100;
   }
   .sidebar-mobile-header-container .sidebar-logo-image{
      max-height: 50%;
   }

   .sidebar-mobile-icons-container{
      display: flex;
      align-items: center;
      gap: 20px;
   }

   .sidebar-mobile--calendarButton{
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
   }

   .sidebar-mobile--calendarIcon{
      width: 100%;
      height: 100%;
      cursor: pointer;
   }

}