.exams__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /*This is a must for every page layout without MainLayout on it*/
    max-width: var(--maxWidthContainer);
    box-shadow: var(--box-shadow-strong);
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    min-height: 100vh;
    padding: 16px 24px;
}