.examQuestion__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.examQuestion__question {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;

    background-color: var(--color-base-light);
    border-radius: var(--border-radius-md);
}

.examQuestion__answers__container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.examQuestion__answer {
    width: 100%;
    height: 100%;
    margin: 16px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: var(--border-radius-md);
}

.examQuestion__question__title {
    width: 100%;
    color: var(--color-text-primary);
    font-size: var(--fs-h5);
    line-height: calc(var(--fs-h5)*1.3);
    text-align: left;
    font-weight: 400;
}

.examQuestion__question__text {
    width: 100%;
    color: var(--color-text-secondary);
    font-size: var(--fs-h2);
    line-height:calc(var(--fs-h2)*1.3);
    text-align: left;
    font-weight: 700;

}

.examQuestion__question__image {
    width: auto;
    height: auto;
    object-fit: contain;
    max-height: 304px;
    margin: 16px 0 0 0;
    align-self: center;
}

.examQuestion__radioGroup__root {
    width: 100%;
    height: auto;
}

.examQuestion__radio__root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;
    cursor: pointer;

    background-color: var(--color-base-light);
    border-radius: var(--border-radius-md);
}

.examQuestion__radio__root--trueAnswer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;

    background-color: var(--color-green--light);
    border-radius: var(--border-radius-md);
}
.examQuestion__radio__root--falseAnswer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 16px;

    background-color: var(--color-red--light);
    border-radius: var(--border-radius-md);
}

.examQuestion__radio__label {
    width: 100%;
    color: var(--color-text-primary) !important;
    font-size: var(--fs-body);
    line-height: var(--fs-body);
    text-align: left;
}

.examQuestion__error {
    width: 100%;
    height: 100%;
    margin-top: 8px;

    font-size: var(--fs-tiny);
    line-height: var(--fs-tiny);
    text-align: center;
    color: var(--color-red);
}

