.CalendarItem-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.CalendarItem--month {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   font-weight: 400;
   text-transform: capitalize;
}

.CalendarItem-items--container {
   display: flex;
   flex-direction: column;
   gap: 10px;
}