.GlassCard-main-container {
   width: 250px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.GlassCard-wrapper-container {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 8px;
   justify-content: space-between;
   padding: 10px;
   border-radius: var(--border-radius-lg);
   background-position: center;
   background-size: cover;
}

.GlassCard--image{
   width: 100px;
   height: 100px;
   object-fit: cover;
   border-radius: 50%;
   box-shadow:var(--box-shadow);
}

.GlassCard-info-wrapper{
   display: flex;
   flex-grow: 1;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 4px;
   width: 100%;
}

.GlassCard-title{
   font-size: var(--fs-small);
   text-align: left;
   color: var(--color-text-primary);
}

.GlassCard-type {
   font-size: var(--fs-tiny);
   text-align: left;
   color: var(--color-text-primary);
   width: fit-content;
   padding: 2px 8px;
   border-radius: var(--border-radius-md);
   outline: 1px solid var(--color-text-primary);
   opacity: .8;
}

.GlassCard-wrapper-container p {
   text-align: center;
   font-size: var(--fs-small);
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   line-clamp: 3;
   -webkit-box-orient: vertical;
}
