.PhoneInputField__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.PhoneInput {
    display: flex;
    width: 100%;
}

.PhoneInputInput {
    width: 100%;
}

.PhoneInputCountry {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.PhoneInputCountrySelect {
    width: 100%;
    height: 36px;
    max-width: 144px;

    appearance: none;
    background-color: #3f6474;
    border: 1px solid #6a9bae;
    border-radius: 0.25rem;
    color: #eef4f6;
    font-family: var(--_input-font-family,var(--mantine-font-family));
    font-size: var(--_input-fz,var(--mantine-font-size-sm));
    line-height: var(--_input-line-height);
    padding: 0 12px;
    text-align: left;
    transition: border-color .1s ease;
}

.PhoneInputCountrySelectArrow {
    display: none;
}

.PhoneInputCountryIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 0 8px;
}

.PhoneInputCountryIconImg {
    display: flex;
    width: 100%;
    height: 100%;
}

.PhoneInputField__error {
    color: var(--mantine-color-error);
    word-wrap: break-word;
    display: block;
    text-align: left;
    width: 100%;
    line-height: 1.2;
    margin: 4px 0 0 0;
    padding: 0;
    font-size: var(--input-error-size,calc(var(--mantine-font-size-sm) - .125rem*var(--mantine-scale)));
}

.PhoneInputField__label {
    -webkit-tap-highlight-color: transparent;
    cursor: default;
    display: inline-block;
    font-size: var(--fs-small) !important;
    font-weight: 500;
    word-break: break-word;
    text-align: left;
    width: 100%;
}