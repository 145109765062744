.CertificateSurveyItem-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   gap: 40px;
   padding: 20px 0;
}

.CertificateSurveyItem-container:nth-child(odd){
   /* background-color: var(--color-base); */
   background-color: #DADADA;
}

.CertificateSurveyItem-container:nth-child(even){
   /* background-color: var(--color-base-dark); */
   background-color: #F5F5F5;
}

.CertificateSurvey-question--text{
   text-align: center;
   font-size: var(--fs-body);
   padding: 0 20px;
   color: var(--color-text-secondary);
   /* color: #2C4B58; */
}


.CertificateSurvey-rate-content{
   display: flex;
   justify-content: space-evenly;
   width: 100%;
   gap: 30px;
}

.CertificateSurvey-rate-content .RateFaceButton-container .RateFaceButton--image{
   height: 60px;
   width: 60px;
}

.RateFaceButton-notRated{
   border: 1px solid #2C4B58;
   display: grid;
   place-items: center;
}

@media (max-width: 768px) {
   .CertificateSurvey-rate-content{
      gap: 20px;
   }
}