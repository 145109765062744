.CertificateSurvey-container{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.CertificateSurvey--textarea-label{
   font-size: var(--fs-body);
   text-align: center;
   color: var(--color-text-secondary);
}

.CertificateSurvey-error-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   padding: 20px;
   gap: 20px;
}

.CertificateSurvey-error--text{
   font-size: var(--fs-body);
   text-align: center;
   /* color: var(--color-red); */
   color: #DC3D3D;
   max-width: 50ch;
}