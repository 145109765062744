.AboutClassInfoCard-rowItem{
   justify-content: space-between;
   align-items: center;
}

.AboutClassInfoCard-row--text{
   font-size: var(--fs-small);
   font-weight: 600;
   color: var(--color-text-secondary);
}

.AboutClassInfoCard-row--data{
   font-size: var(--fs-small);
   font-weight: 400;
   color: var(--color-text-primary);
   text-align: right;
}

.ClassDetails--decorativeLine{
   width: 100%;
   height: 1px;
   background-color: #808080;
}