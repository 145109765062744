

.OwnCoursesList-grid-container .activeCardCourse-column-div{
   width: 100%;
   height: auto;
}

.OwnCoursesList-grid-container .activeCardCourse-column-div .activeCardCourse-content-div{
   width: 100%;
   height: auto;
}

.OwnCoursesList-grid-container .activeCardCourse-column-div .activeCardCourse-content-div .activeCardCourse-info-div{
   width: 100%;
   height: auto;
}

@media (max-width: 768px) {
   .OwnCoursesList-grid-container{
      grid-template-columns: 1fr;
   }
}