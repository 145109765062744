.RegisterPage-container {
   position: relative;
   min-height: 100vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 40px 0;
}

.RegisterPage-content {
   position: relative;
   overflow: clip;
   /* background: rgb(222, 226, 230);
   background: linear-gradient(270deg, rgba(222, 226, 230, 1) 0%, rgba(214, 214, 214, 1) 55%, rgba(218, 218, 218, 1) 100%); */
   /* background-color: #CCD4DA; */
   background-color: var(--color-base-dark);
   border-radius: var(--border-radius-sm);
   padding: 40px;
   max-width: 576px;
   width: 100%;
   gap: 40px;
   display: flex;
   flex-direction: column;
   box-shadow: var(--box-shadow);
}

.RegisterPage-up-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.RegisterPage-left-title {
   font-family: var(--font-primary);
   font-size: var(--fs-h3);
   font-weight: normal;
   color: var(--color-text-secondary);
   text-align: center;
}

@media (max-width: 576px) {
   .RegisterPage-content{
      border-radius: 0;
   }
}