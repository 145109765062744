.cousesSkeleton__wrapper {
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.cousesSkeleton__container {
    /*48px of required layout padding*/
    max-width: calc(var(--maxWidthContainer) - var(--sidebar-width) - 48px);
    width: calc(100vw - var(--sidebar-width) - var(--full-layout-padding-x) - 8px);
    margin-top: 40px;
}