.examTimer__container {
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 24px;
}

.examTimer__text {
    color: var(--color-text-secondary);
    font-size: var(--fs-body);
    text-align: center;
    width: auto;
    min-width: 80px;
}
.examTimer__text--bold {
    color: var(--color-text-secondary);
    font-size: var(--fs-body);
    text-align: left;
    font-weight: 700;
    margin-right: 8px;
}

.examTimer__clock {
    color: var(--color-text-secondary);
    margin-right: 8px;
    stroke-width: 2px;
}

.examTimer__eye {
    color: var(--color-primary-light);
    margin-left: 8px;
    cursor: pointer;
}

@media (max-width: 668px) {
   .questionIndicator__container, .examTimer__container{
      margin: 0;
   }
   .examTimer__container{
      flex-wrap: wrap;
   }
   .examTimer__clock {
      flex-shrink: 0;
   }
   .examTimer__text--bold{
      width: max-content;
   }

}