.UserProfileCarousel-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;

}

.UserProfileCarousel--title{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
}

.UserProfileCarousel--carousel{
   grid-area: carrusel;
   border-radius: var(--border-radius-sm);
}

.UserProfileCarousel--image {
   object-fit: cover;
   width: 100%;
   height: 100%;
   max-height: 550px;
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
}

.UserProfile-form-container .PhoneInputCountrySelect{
   background-color: var(--color-base-light);
   color: var(--color-text-primary);
   border-color: #ced4da;
}