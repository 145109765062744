.RateClassBanner-notification-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 30px;
   height: 100%;
   width: 100%;
   color: #77BDE0;

}

.RateClassBanner-notification-container p {
   text-align: center;
}

.RateClassBanner-notification-p-first {
   font-weight: 700;
   background-color: #77BDE01A;
   padding: 10px 20px;
   border-radius: var(--border-radius-sm);
   font-size: var(--fs-h4);
}

.RateClassBanner-notification-p-container {
   display: flex;
   flex-direction: column;
   gap: 10px;
   font-size: var(--fs-body);
}

.RateClassBanner-notification-p-container p:last-child {
   font-weight: 600;
}

.RateClassBanner-notification-p-first {
   font-weight: 700;
}

.RateClassBanner-notification--textRate{
   color: var(--color-white);
   font-weight: 700;
   padding: 10px 20px;
   font-size: var(--fs-body);
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
}

.RateClassBanner-notification-good {
   background-color: #0a570a;
}

.RateClassBanner-notification-regular {
   background-color: var(--color-yellow);
}

.RateClassBanner-notification-bad {
   background-color: var(--color-red);
}