.dashboard__layout {
    display: grid;
    grid-template-areas: "header header"
                        "main calendar";
    grid-template-columns: auto var(--calendar-width);
    /*This height is dashboard__header__userName font-size*/
    grid-template-rows: calc(var(--fs-h3) * 1.2) auto;
    gap: 16px 32px;
    transition: var(--sidebar-transition);

    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.dashboard__layout--collapsed {
    display: grid;
    grid-template-areas: "header header"
                        "main calendar";
    grid-template-columns: auto var(--calendar-width-collapsed);
    grid-template-rows: auto;
    transition: var(--sidebar-transition);
    padding: 8px 24px;
    gap: 16px 32px;
}

.dashboard__calendar {
    grid-area: calendar;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
}

.dashboard__header {
    grid-area: header;
    width: 100%;
}

.dashboard__main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dashboard__header__userName {
    color: var(--color-text-secondary);
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3) * 1.2);
}

@media (min-width: 1001px) and (max-width: 1200px) {
    .dashboard__layout {
        padding: 16px 24px;
        gap: 16px 32px;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .dashboard__layout {
        display: flex;
        flex-direction: column;
        padding: 24px 32px;
    }
}

@media (max-width: 768px) {
    .dashboard__layout {
        display: flex;
        flex-direction: column;
        padding: 24px 32px;
    }
}

