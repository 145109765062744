.LoginHeader--Menu {
   position: absolute;
   top: 50px;
   left: 0;
   width: 100%;
   z-index: -1;
   opacity: 0;
   display: flex;
}

.LoginHeader-Menu--active {
   z-index: 1000;
   opacity: 1;
}

.LoginMegaMenu-carousel-wrapper {
   margin-top: 20px;
   padding: 20px 0 40px 0;
   width: 100%;
   display: flex;
   gap: 32px;
   flex-direction: column;
   border-radius: var(--border-radius-md);
   background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);
   box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);

}

.LoginMegaMenu--typeTitle {
   text-align: center;
   font-size: var(--fs-h1);
   color: var(--color-text-secondary);
}

.LoginMegaMenu--cardSlide {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: calc(var(--fs-h5) * 4) max-content 1fr;
   width: 100%;
   height: 100%;
   max-width: 400px;
   border-radius: var(--border-radius-md);

   background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7));
   backdrop-filter: blur(10px);
   -webkit-backdrop-filter: blur(10px);

   box-shadow: var(--box-shadow);
   padding: 16px;
}

.LoginMegaMenu-cardSlide--title-wrapper{
   position: relative;
}

.LoginMegaMenu-cardSlide--title {
   font-size: var(--fs-h5);
   text-align: left;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp:3; 
   line-clamp:3;
   overflow: hidden;
   color: var(--color-text-secondary);
}

.LoginMegaMenu-cardSlide--title-wrapper::after {
   content: "";
   position: absolute;
   bottom: -20px;
   left: 0;
   width: 100%;
   height: 1px;
   background-color: var(--color-text-primary);
}

.LoginMegaMenu-cardSlide--content {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 40px;
   gap: 16px;
}


.LoginMegaMenu-CardSlide-label {
   font-size: var(--fs-body);
   font-weight: 700;
}

.LoginMegaMenu-CardSlide--text {
   font-size: var(--fs-small);
   opacity: .9;
   color: var(--color-text-primary);
}

.LoginMegaMenu-CardSlide--price {
   font-size: var(--fs-h5);
   color: var(--color-text-primary);
   font-weight: 700;
}

.LoginMegaMenu-CardSlide--textPrice {
   font-size: var(--fs-h3);
   color: var(--color-text-secondary);
   font-weight: 700;

}

.LoginMegaMenu-button-container {
   width: 100%;
   flex-grow: 1;
   display: flex;
   align-items: flex-end;
   justify-content: center;
}

@media (max-width: 1100px) {

   .LoginMegaMenu--cardSlide {
      grid-template-rows: calc(var(--fs-h6) * 4) max-content 1fr;
   }

   .LoginMegaMenu-cardSlide--title {
      font-size: var(--fs-h6);
   }
}