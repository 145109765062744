.passwordChecker__wrapper {
    font-size: var(--fs-small);
    font-weight: 600;
    color: var(--color-text-secondary);
    background-color: var(--color-primary-lightest-transparent);
    border-radius: var(--border-radius-sm);
    padding: 8px 16px;
    margin-bottom: 16px;
}

.passwordChecker__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 16px;
}

.passwordChecker__container p {
    font-size: var(--fs-small);
    font-weight: 500;
    color: var(--color-text-secondary);
}