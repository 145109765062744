.OpportunitiesSlide--container{
   width: 100%;
   height: 100%;
   display: flex;
   border-radius: var(--border-radius-md);
   background-position: center;
   background-size: cover;
   cursor: pointer;
}

.OpportunitiesSlide--overlay--container {
   height: 100%;
   width: 100%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 15px;

   background-color: #0000008f;
   /*padding: 30px;*/
   border-radius: var(--border-radius-md);
}

/*This was required to avoid X oversize (It still has a little bit)*/
.OpportunitiesSlide--overlay {
   padding: 32px 0 32px 32px;
   width: 80%;
}

.OpportunitiesSlide--title{
   font-size: var(--fs-h3);
   color: var(--color-white);
   font-weight: 600;

}

.OpportunitiesSlide--description{
   font-size: var(--fs-body);
   color: var(--color-white);
   text-wrap: balance;
   width: 50ch;
}

@media screen and (max-width: 768px) {
   .OpportunitiesSlide--overlay--container {
      align-items: center;
   }

   .OpportunitiesSlide--title, .OpportunitiesSlide--description{
      text-align: center;
   }
}
