.materialTable__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    width: 100%;

    padding: 24px;
    border-radius: var(--border-radius-md);
    background-color: var(--color-primary-lightest);
}

.materialsTable__cell {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    height: auto;
    width: 100%;
}

.materialsTable-header--actions{
   display: flex;
   justify-content: flex-end;
}

.materialsTable__row-container td:last-child .materialsTable__cell {
   justify-content: flex-end;
}

.materialsTable--max-width{
   width: 60px;
}

.materialsTable__row--selected {
    border-radius: var(--border-radius-md);
    background-color: var(--color-primary-darkest);
}

.materialsTable--fileName{
   text-transform: uppercase;
}

.materialsTable__icon {
    cursor: pointer;
    margin: 0 4px;
}

@media (max-width: 768px) {
   .materialsTable--max-width{
      width: unset;
   }
}