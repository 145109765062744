.ForoPageHeader-container{
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.ForoPageHeader-title{
   font-size: var(--fs-h1);
   line-height: calc(var(--fs-h1)*1.8);
   color: var(--color-text-secondary);
}

.ForoPageHeader-buttons-container{
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.ForoPageHeader-filterButton-container{
   display: flex;
   align-items: center;
   justify-content: center;
}

.ForoPageHeader--buttonGroup{
   display: flex;
   gap: 20px;
   flex-grow: 1;
   justify-content: flex-end;
}


.ForoPageHeader-button--icon{
   margin-right: 10px;
   flex-shrink: 0;
}

.ForoPageHeader--mobile-container{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
@media (width < 1100px) {
   .ForoPageHeader--buttonGroup{
      gap: 5px;
   }
}