.helpBody-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.helpBody-accordion-div{
    margin: 40px;
}

.helpBody-accordion-control{
    margin: 16px 0 0 0;
    box-shadow: var(--box-shadow-strong);
    border-radius: var(--border-radius-sm);
    font-size: var(--fs-small) !important;
    line-height: calc(var(--fs-small) * 1.6)  !important;
}

.helpBody-accordion-panel{
    font-size: var(--fs-tiny) !important;
    line-height: calc(var(--fs-tiny) * 1.8)  !important;
}

@media (max-width: 768px) {
    .helpBody-accordion-div{
        margin: 12px;
    }
}