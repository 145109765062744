.sponsorBanner-link-navigation {
    text-decoration: none;
}

.sponsorBanner-container-div {
    display: flex;
    position: relative;
    overflow: hidden;
    min-height: 150px;
    height: 100%;
    border-radius: var(--border-radius-sm);
    box-shadow: var(--box-shadow);
}

.sponsorBanner-image-custom {
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
}

.sponsorBanner-content-container {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    box-shadow: 0 56px 64px 0 rgba(0,0,0,0.75) inset;
}

.sponsorBanner-title-for-sponsor {
    color: var(--color-text-tertiary);
    font-size: var(--fs-h4);
    line-height: var(--fs-h4);
}