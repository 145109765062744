.ForumRulesModal-main{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 40px;
}

.ForumRulesModal-header-container{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.ForumRulesModal-header--text{
   font-size: var(--fs-body);
   /* color: var(--color-text-secondary); */
   /*TODO: Review after merge issue: */
   color: #2C4B58;
}

.ForumRulesModal-content{
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.ForumRulesModal-item--container{
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.ForumRulesModal-item--header{
   display: flex;
   width: 100%;
   gap: 5px;
   align-items: center;
   flex-wrap: wrap;
}

.ForumRulesModal-item--title{
   font-size: var(--fs-body);
}

.ForumRulesModal-item--text{
   font-size: var(--fs-small);
   text-wrap: balance;
   color: var(--color-text-primary);
}

.ForumRulesModal-footer-container{
   display: flex;
   justify-content: center;
   align-items: center;
}