.sidebar-navLink-container {
    text-decoration: none;
    color: var(--color-base-light);
    padding: 6px 12px;
    font-size: var(--fs-body);
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: var(--border-radius-sm);
    z-index: 10;
    flex-wrap: nowrap;
}

.sidebar-navLink-container:hover {
   background-color: var(--color-primary-light);
}

.sidebar-navLink-container.active {
    background-color: var(--color-base-light);
    color: var(--color-primary-dark);
    font-weight: 700;
}

.sidebar-navLink-text {
    white-space: nowrap;
    font-family: var(--font-primary);
}

.sidebar-navLink--icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
}


@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .sidebar-navLink-container {
        padding: 6px 12px;
        font-size: var(--fs-body);
        gap: 16px;
    }

    .sidebar-navLink--icon {
        height: 24px;
        width: 24px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
   .sidebar-navLink-container {
      padding: 6px 12px;
      font-size: var(--fs-small);
      line-height: var(--fs-small);
      gap: 8px;
   }

   .sidebar-navLink--icon {
      height: 20px;
      width: 20px;
   }
}
