.ForoPageContent-container{
   display: flex;
   flex-direction: column;
   gap: 50px;
}

.ForoPageContent-error-container{
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
}

.ForoPageContent-error--text{
   text-wrap: balance;
   font-size: var(--fs-h3);
   text-align: center;
   color: var(--color-red);
}