.coursesMain-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.coursesMain-material-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
}

.coursesMain-material--buttonText {
    font-size: var(--fs-small);
    margin-right: 5px;
}

.coursesMain-material-table-container {
    width: 100%;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.coursesMain-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
}

.coursesMain-errorText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: var(--fs-h3);

    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
}

.noLessonCard__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px 32px;
    background-color: var(--color-base-dark);
    border-radius: var(--border-radius-sm);

    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.2);
    color: var(--color-text-secondary);
    font-weight: 500;
    text-align: center;

    margin-top: 32px;
}