.sponsorCard-link-navigation {
    text-decoration: none;
}

.sponsorCard-container-div {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: var(--border-radius-sm);
}

.sponsorCard-image-custom {
    object-fit: cover;
    height: 100%;
    max-height: 100%;
    position: absolute;
    z-index: 1;
}

.sponsorCard-content-container {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    box-shadow: 0 56px 64px 0 rgba(0,0,0,0.75) inset;
    height: 100%;
    min-height: 180px;
}

.sponsorCard-title-for-sponsor {
    color: var(--color-text-tertiary);
    font-size: var(--fs-h4);
    line-height: var(--fs-h4);
}