.advancedQueryForm-headerTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: var(--fs-h2);
  line-height: var(--fs-h2);
  margin-top: 16px;
}

.advancedQueryForm-headerSubTitle {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 16px 0;
  font-size: var(--fs-h4);
  line-height: var(--fs-h4);
}

.advancedQueryForm-formDiv {
  padding: 16px;
  border: solid 1px grey;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow);
}

.advancedQueryForm-button-root {
  margin: 8px 0;
}
