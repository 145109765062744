.sidebar-container {
   position: fixed;
   max-width: var(--sidebar-width);
   width: 100%;
   height: 100vh;
   max-height: 100%;
   padding: 8px;
   background-color: transparent;
   transition: var(--sidebar-transition);
   z-index: 100;
}

.sidebar-content {
   overflow: hidden;
   position: relative;
   width: 100%;
   height: 100%;
   padding: 16px 32px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 32px;
   background-color: var(--color-primary-dark);
   color: var(--color-base-light);
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
   transition: var(--sidebar-transition);
}

.sidebar-back-icon {
   position: absolute;
   left: 20px; /* 24px - 4px padding inside icon */
   top: 12px; /* 16px - 4px padding inside icon */
   cursor: pointer;

   background-color: transparent;
   transition: var(--sidebar-transition);
}


.collapse {
   -moz-transform: scaleX(-1);
   -webkit-transform: scaleX(-1);
   -o-transform: scaleX(-1);
   transform: scaleX(-1);
   -ms-filter: fliph;
   filter: fliph;
   transition: var(--sidebar-transition);
}

/* cambiando cuando esta collapsed */
.sidebar-container.sidebar-collapsed {
   max-width: var(--sidebar-width-collapsed);
   transition: var(--sidebar-transition);
}

.sidebar-container.sidebar-collapsed .sidebar-content {
   padding: 40px 6px;
}

.sidebar-container.sidebar-collapsed .sidebar-nav-container {
   align-items: center;
}

.sidebar-container.sidebar-collapsed .sidebar-header-container {
   padding-top: 50px;
}

.sidebar-container.sidebar-collapsed .sidebar-logo-container {
   display: none;
   transition: var(--sidebar-transition);
}

.sidebar-container.sidebar-collapsed .sidebar-avatar-container {
   height: calc(var(--sidebar-width-collapsed) - 30px);
   width: calc(var(--sidebar-width-collapsed) - 30px);
   outline: 1px solid var(--color-base-light);
   transition: var(--sidebar-transition);
}

.sidebar-container.sidebar-collapsed .sidebar-avatar-image {
   max-height: 100px;

}

.sidebar-container.sidebar-collapsed .sidebar-user-name {
   display: none;
   transition: var(--sidebar-transition);

}

.sidebar-container.sidebar-collapsed .sidebar-navLink-container .sidebar-navLink-text {
   display: none;
   transition: var(--sidebar-transition);

}

.sidebar-container.sidebar-collapsed .sidebar-footer-container .sidebar-navLink-container .sidebar-navLink-text {
   display: none;
   transition: var(--sidebar-transition);

}

.sidebar-container.sidebar-collapsed .sidebar-footer-container {
   align-items: center;
}

@media (min-width: 1301px) and (max-width: 1500px) {
   .sidebar-back-icon {
      position: absolute;
      left: 20px; /* 24px - 4px padding inside icon */
      top: 12px; /* 16px - 4px padding inside icon */
      cursor: pointer;

      background-color: transparent;
      transition: var(--sidebar-transition);
   }
}

@media (min-width: 1000px) and (max-width: 1200px) {
   .sidebar-content{
      padding: 16px 24px;
   }

}


@media (min-width: 768px) and (max-width: 1000px) {

   .sidebar-container {
      position: absolute;
      max-width: 100%;
      padding: 0;
      left: -100%;
   }

   .sidebar-content {
      min-height: calc(100vh - var(--headerHeight));
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      position: absolute;
      left: 0;
      top: var(--headerHeight);

      color: var(--color-white);
      border-radius: 0;
      box-shadow: var(--box-shadow);
      transition: var(--sidebar-transition);
      z-index: 100;
      padding: 16px 24px;
   }

   .sidebar-user-name {
      text-align: center;
   }

   /* NAVBAR MOBILE */
   .navbar-open {
      left: 100%;
      z-index: 100;
      transition: var(--sidebar-transition);
   }
   .sidebar-back-icon{
      display: none;
   }
}

@media screen and (max-width: 768px) {
   .sidebar-container {
      position: absolute;
      max-width: 100%;
      padding: 0;
      left: -100%;
   }

   .sidebar-content {
      /* padding-top: var(--headerHeight); */
      /* padding-bottom: 10%; */
      min-height: calc(100vh - var(--headerHeight));
      align-items: center;
      justify-content: flex-start;
      /* gap: 50px; */
      gap: 8px;
      position: absolute;
      left: 0;
      top: var(--headerHeight);

      color: var(--color-base-light);
      border-radius: 0;
      box-shadow: var(--box-shadow);
      transition: var(--sidebar-transition);
      z-index: 100;
   }

   .sidebar-user-name {
      text-align: center;
   }

   /* NAVBAR MOBILE */
   .navbar-open {
      left: 100%;
      z-index: 100;
      transition: var(--sidebar-transition);
   }
   .sidebar-back-icon{
      display: none;
   }
}








