.coursesCards-reExam-father-div {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-sm);
  margin: 16px 0;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.coursesCards-reExam-father-div:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background-color: var(--color-tertiary-light);
  height: 40px;
  width: 40px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.coursesCards-reExam-father-div:hover:before {
  transform: scale(21);
}

.coursesCards-reExam-father-div:hover p {
  transition: all 0.3s ease-out;
}

.coursesCards-reExam-div-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-body);
  padding: 24px;
  width: 15%;
  background-color: var(--color-tertiary);
}

.coursesCards-reExam-text-span-date {
  font-size: var(--fs-body);
  color: var(--color-text-tertiary);
}

.coursesCards-reExam-span-date {
  font-size: var(--fs-h3);
  color: var(--color-text-tertiary);
}

.coursesCards-reExam-description-color {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
}

.coursesCards-reExam-description {
  font-size: var(--fs-body);
  color: var(--color-text-primary);
  width: 90%;
  padding: 0 0 0 8px;
}

.coursesCards-reExam-buttons-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

/* Card popping up effect */
.coursesCards-reExam-father-div {
  transition: all 0.2s ease-out;
  top: 0;
}

.coursesCards-reExam-father-div:hover {
  top: -4px;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .coursesCards-reExam-father-div {
    margin: 16px 0;
    overflow: hidden;
    width: 100%;
  }

  .coursesCards-reExam-father-div:before {
    top: -16px;
    right: -16px;
    height: 40px;
    width: 40px;
    border-radius: 32px;
  }

  .coursesCards-reExam-father-div:hover:before {
    transform: scale(21);
  }

  .coursesCards-reExam-description {
    width: 90%;
    padding: 0 0 0 8px;
  }

  .coursesCards-reExam-father-div:hover {
    top: -4px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .coursesCards-reExam-father-div {
    margin: 16px 0;
    overflow: hidden;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .coursesCards-reExam-father-div {
    flex-direction: column;
    align-items: center;
  }

  .coursesCards-reExam-div-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px;
  }

  .coursesCards-reExam-text-span-date {
    font-size: var(--fs-h3);
    color: var(--color-text-tertiary);
  }

  .coursesCards-reExam-span-date {
    margin-left: 8px;
  }

  .coursesCards-reExam-description-color {
    flex-direction: column;
  }

  .coursesCards-reExam-description {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 8px 0;
    font-size: var(--fs-small);
  }

  .coursesCards-reExam-buttons-div {
    margin: 8px;
  }

  .coursesCards-reExam-father-div:before {
    display: none;
  }
}
