.PrevExamPage-container {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin: 0 auto;
   gap: 24px;
   color: var(--color-text-secondary);

   /*This is a must for every page layout without MainLayout on it*/
   max-width: var(--maxWidthContainer);
   box-shadow: var(--box-shadow-strong);
   /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
   overflow-y: scroll;
   height: 100%;
   min-height: 100vh;
   padding: 16px 24px;
}

.PrevExamPage-subtitle {
   font-size: var(--fs-h3);
   font-weight: 600;
}

.PrevExamPage-container .coursesClassHeaderNavigation-classTitle-div{
   justify-content: flex-start;
}

.PrevExamPage-error-message{
   width: 100%;
   font-size: var(--fs-h3);
}

@media (max-width: 576px) {
   .PrevExamPage-subtitle{
      text-align: center;
   }

   .PrevExamPage-container .coursesClassHeaderNavigation-classTitle-div{
      justify-content: center;
      text-align: center;
   }

   .PrevExamPage-error-message{
      text-align: center;
   }

}
