.UserProfilePage-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 24px;

   /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
   overflow-y: scroll;
   height: 100%;
   padding: 16px 24px;
}

.UserProfile-content--row {
   width: 100%;
   /* display: flex;
   gap: 20px;
   justify-content: space-between;
   flex-wrap: wrap; */
   display: grid;
   grid-template-columns: 1fr 300px;
   grid-template-areas: "form carrusel";
   gap: 10%;
}

@media (max-width: 1200px) and (min-width: 1101px) {
   .UserProfilePage-container .RegisterPage-avatar-group {
      display: flex;
      flex-direction: column;
   }
   .UserProfile-content--row{
      gap: 5%;
   }

   .UserProfilePage-container .RegisterPage-avatar-container{
      order: 0;
   }

   .UserProfilePage-container .RegisterPage-avatar--input{
      height: 50px;
      order: 2;
   }

   .UserProfilePage-container .RegisterPage-avatar-userInfo-container{
      order: 3;
   }


}


@media (max-width: 1100px) {
   
   .UserProfile-content--row {
      display: flex;
      flex-direction: column;
      gap: 50px;
   }
}

@media (max-width: 900px) {
   .UserProfilePage-container .RegisterPage-avatar-group {
      display: flex;
      flex-direction: column;
   }

   .UserProfilePage-container .RegisterPage-avatar-container{
      order: 0;
   }

   .UserProfilePage-container .RegisterPage-avatar--input{
      order: 2;
   }

   .UserProfilePage-container .RegisterPage-avatar-userInfo-container{
      order: 3;
   }
}
