.OwnCoursesList-container{
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.OwnCoursesList-error-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 30px;
   align-items: center;

}

.OwnCoursesList-error--text{
   font-size: var(--fs-h3);
   text-wrap: balance;
   text-align: center;
   color: var(--color-red);
}

.OwnCoursesList-noResults--text{
   font-size: var(--fs-h3);
   color: var(--color-text-primary);
}