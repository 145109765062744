.forumDiscussion-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.forumDiscussion-father-div {
    display: flex;
    position: relative;
    flex-direction: column;
}

.forumDiscussion-father-div-mobile{
  margin: 16px 0;
}

.forumDiscussion-header-title {
    font-size: var(--fs-h2);
    margin: 16px 0;
    color: var(--color-text-secondary);
}

.forumDiscussion-themeCard-container{
  overflow: hidden;
}

.forumDiscussion-answers-div {
    margin: 0 0 8px 0;
}

.forumDiscussion-answers-title {
    font-size: var(--fs-h3);
    margin: 16px 0;
    color: var(--color-text-secondary);
}

.forumDiscussion-textInput-div {
    position: sticky;
    bottom: 8px;
    padding: 8px 8px 0 8px;
    border-radius: var(--border-radius-sm);
    background-color: var(--color-primary-lightest);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.forumDiscussion-textInput-input{
  border-radius: var(--border-radius-sm) !important;
}

/* .forumDiscussion-button-root {
    margin: 8px 0 0 0;
} */

