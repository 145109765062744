.certificates-layout{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--color-text-secondary);

    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    padding: 16px 24px;
}

.certificates-title {
  font-size: var(--fs-h2);
}

.certificates-subtitle {
  font-size: var(--fs-h3);
  margin: 24px 0;
}
/* Classes only used while no certificates are available */
.certificates-noCertificates-div {
  display: flex;
  width: 100%;
}

.certificates-noCertificates-textDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.certificates-noCertificates-header {
  font-size: var(--fs-h4);
  line-height: calc(var(--fs-h4) * 1.4);
}

.certificates-noCertificates-textContent {
  font-size: var(--fs-body);
  line-height: calc(var(--fs-body) * 1.4);
}

.certificates-noCertificates-sproutImage{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    height: 100%;
    object-fit: contain;
}

.certificates-noCertificates-image {
  max-height: 300px;
  height: 100%;
  margin-left: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .certificates-noCertificates-div {
    flex-direction: column-reverse;
    margin: 16px 0 0 0;
  }

  .certificates-noCertificates-textDiv {
    align-items: center;
    justify-content: center;
  }

  .certificates-noCertificates-image {
    max-height: 250px;
    height: 100%;
    margin-left: 0;
  }
}
