.ClassDetailsCards-container{
   display: grid;
   width: 100%;
   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
   gap: 30px;
}

.ClassDetails-card-container{
   /* max-width: 350px; */
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 15px;
   font-family: var(--font-primary);
   background-color: var(--color-base-light);
   box-shadow: var(--box-shadow);
   padding: 15px;
   border-radius: var(--border-radius-sm);
}

.ClassDetails-card--row{
   width: 100%;
   display: flex;
   gap: 10px;
}

.ClassDetails-card-headline{
   font-size: var(--fs-h4);
   color: var(--color-text-secondary);
}

.ClassDetails-card-text{
   color: var(--color-text-primary);
   font-size: var(--fs-small);

}


@media (max-width: 768px) {
   .ClassDetailsCards-container{
      display: flex;
      flex-direction: column;
      grid-template-columns: none;
   }
}