.advancedQueryForm-contact-wrapper {
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-lg);
    margin: 16px 0;
    padding: 4px;
  }
  
  .advancedQueryForm-contact-title {
    font-family: var(--font-primary);
    font-size: var(--fs-h3);
    line-height: var(--fs-h3);
    text-align: center;
  }
  
  .advancedQueryForm-contact-items {
    display: flex;
    gap: 8px;
  }
  
  /* this class will render if isTablet */
  .advancedQueryForm-contact-items-mobile {
      flex-direction: column;
      padding-left: 16px;
  }
  