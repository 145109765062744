.classNotes-father-div {
   display: flex;
   flex-direction: column;
}

.classNotes-saveNotes-button {
   margin: 16px 0 0 0;
}

.classNotes-download-button {
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   margin: 0 0 16px 0;
}

.ClassNotes-RichTextEditor--content {
   white-space: pre-wrap;
   word-break: break-all;
}

.classNotes-savedNotes-father-div {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   border-radius: var(--border-radius-sm);
   background-color: var(--color-white);
   max-width: 1104px;
   width: 100%;
   box-shadow: var(--box-shadow);
   padding: 8px;
}

.NoteClassCard--content {
   max-width: 100%;
   width: 100%;
   text-wrap: wrap;
}

.classNotes-savedNotes-content {
   font-size: var(--fs-body);
   line-height: calc(var(--fs-body)*1.4);
   white-space: pre-wrap;
   word-break: break-word;
}

.RichTextEditor--paragraph {
   font-size: var(--fs-body);
   white-space: pre-wrap;
   word-break: break-word;
   line-height: 1.7rem;
}

.tiptap h1.RichTextEditor--heading {
   font-size: var(--fs-h2);
}

.tiptap h2.RichTextEditor--heading {
   font-size: var(--fs-h3);
}

.tiptap h3.RichTextEditor--heading {
   font-size: var(--fs-h4);
}

.tiptap h4.RichTextEditor--heading {
   font-size: var(--fs-h5);
}