.certificatesCard-layout{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 24px;
}

.certificatesCard-father-div{
    border-radius: var(--border-radius-sm);
    background: linear-gradient(180deg, rgba(10,3,60,1) 45%, rgba(255,255,255,1) 40%);
}

.certificatesCard-image{
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: 8px 0;
}

.certificatesCard-infoDiv{
    margin: 0 20px;
}

.certificatesCard-courseTitle{
    margin: 16px 0 8px 0;
    font-size: var(--fs-small);
}

.certificatesCard-dateText{
    font-size: var(--fs-tiny);
    margin: 0 8px;
}

.certificatesCard-buttons-textSize{
    margin-left: 8px;
    font-size: var(--fs-small);
}

.certificatesCard-secondButton-root{
    margin-bottom: 20px;
}