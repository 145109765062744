.CourseCardSkeleton-container{
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.CourseCardSkeleton-grid-container{
   display: grid;
   grid-template-columns: repeat(
      auto-fill,
      minmax(500px, 1fr)
   );
   gap: 30px;
}

.CourseCard-skeleton-card-container{
   padding: 20px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 10px;
   width: 100%;
   height: 200px;
   background-color: #0000002a;
   border-radius: var(--border-radius-md);
}

.CourseCard-skeleton--row{
   width: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   gap: 10px;
   height: 60%;
}

.CourseCard-skeleton--text-container{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 100%;
   height: 100%;
}

.CourseCard-skeleton--title{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.CourseCard-skeleton-footer{
   width: 100%;
}