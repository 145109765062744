.ForoCardContent-container{
   display: flex;
   flex-direction: column;
   gap: 15px;
   /*TODO: Review this value*/
   max-width: 1040px;
   width: 100%;
   color: inherit;
}

.ForoCardContent-body{
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   gap: 10px;
}

.ForoCardContent--title{
   color: var(--color-text-secondary);
   font-size: var(--fs-h4);
}

.ForoCardContent--text{
   color: var(--color-text-secondary);
   font-size: var(--fs-body);
   white-space: pre-wrap;
   word-break: break-word;
}
/* render this class only if ForumPageContent== true. */
.ForoCardContent--text-forumPageContent-style{
   color: var(--color-text-secondary);
   font-size: var(--fs-body);
   white-space: pre-wrap;
   word-break: break-word;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 4;
   overflow: hidden;
}

.ForoCardContent-tag-container{
   display: flex;
   align-items: flex-start;
}

.ForoCardContent-tag--text{
   font-size: var(--fs-small);
   padding: 6px 12px;
   background-color: var(--color-tertiary);
   border-radius: var(--border-radius-sm);
   text-transform: uppercase;
   color: var(--color-base-light);
}

.ForoCardContent-body .ProseMirror{
   padding: 0 !important;
}

@media screen and (max-width: 1100px) {
   .ForoCardContent-tag--text{
      font-size: var(--fs-tiny);
   }
}