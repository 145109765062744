.ForoCard-header{
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.ForoCard-header--userInfo{
   display: flex;
   align-items: center;
   justify-content: flex-start;
   gap: 10px;
   flex-grow: 1;
}

.ForoCard--username{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   font-weight: 700;
}

.ForoCard-header--commentInfo{
   display: flex;
   align-items: flex-start;
}

.ForoCard-header-commentInfo--container{
   display: flex;
   gap: 5px;
   align-items: center;
}

.ForoCard-header-commentInfo--amount{
   font-size: var(--fs-small);
   color: var(--color-text-primary);
   font-weight: 400;
}