.discountBanner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--mantine-radius-md);
  }

  .discountBanner-body {
    display: flex;
    flex-direction: column;
  }

  .discountBanner.title {
    color: var(--mantine-color-black);
    font-family: Greycliff CF, var(--mantine-font-family);
    line-height: 1;
    margin-bottom: var(--mantine-spacing-md);
  }
  
  .discountBanner-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: var(--mantine-spacing-xl);
  }

  .discountBanner-secondButtons{
    display: flex;
    gap: 32px;
  }
  
  .discountBanner-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  
  @media (max-width: 768px) {
    .discountBanner-wrapper {
      flex-direction: column-reverse;
      padding: var(--mantine-spacing-xl);
    }
  }
  
  @media (max-width: 768px) {
    .discountBanner-body {
      padding-right: 0;
      margin-top: var(--mantine-spacing-xl);
    }
  }