.reminderAndSponsor-header {
    font-size: var(--fs-h3);
    margin: 16px 0 16px 0;
    color: var(--color-text-secondary);
}

.reminderAndSponsor-noText {
    width: 100%;
    color: var(--color-text-primary);
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body)*1,2);
    text-align: center;
}