.activeCardCourse-column-div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    min-height: 210px;
}

.activeCardCourse-father-div {
    display: flex;
    background-color: var(--color-primary-light);
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
    box-shadow: var(--box-shadow);
    padding: 16px 16px 8px;
}

.activeCardCourse-image-div {
    width: 30%;
}

.activeCardCourse-img {
    width: 100%;
    height: 100%;
    max-height: 104px;
    border-radius: var(--border-radius-sm);
    object-fit: cover;
}

.activeCardCourse-info-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
    width: 70%;
}

.activeCardCourse-course-title {
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3) * 1.2);
    color: var(--color-text-tertiary);
    padding: 0;
}

.activeCardCourse-course-description {
    font-size: var(--fs-small);
    color: var(--color-text-tertiary);
}

.activeCardCourse-course-description span {
    margin-left: 8px;
}

.activeCardCourse-progressBar-div {
    border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
    padding: 16px;
    background-color: var(--color-white);
}

.activeCardCourse-percentage-complete {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
}

.activeCourseCard-error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-sm);
    padding: 0 24px;
}

.activeCourseCard-errorState {
    width: 100%;
    text-align: center;
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small) * 1.2);
    margin-bottom: 8px;
}

@media (min-width: 1001px) and (max-width: 1400px) {
    .activeCardCourse-course-title {
        font-size: var(--fs-h4);
        line-height: calc(var(--fs-h4) * 1.2);
    }
}

@media (min-width: 769px) and (max-width: 1000px) {
    .activeCardCourse-course-title {
        font-size: var(--fs-h4);
        line-height: calc(var(--fs-h4) * 1.2);
    }
}

@media (max-width: 768px) {
    .activeCardCourse-course-title {
        padding: 8px;
    }

    .activeCardCourse-course-description {
        padding: 0 0 8px 8px;
    }
}

/* @media (min-width:500px) and (max-width: 768px) {
    .activeCardCourse-image-div{
        width: 40%;
        height: 100%;
    }

    .activeCardCourse-img{
        height: 100%;
    }
  }
@media (min-width:100px) and (max-width: 500px) {
    .activeCardCourse-image-div{
        width: auto;
        height: 100%;
    }

    .activeCardCourse-img{
        height: 100%;
    }
  } */
