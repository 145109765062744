.CertificateOptions-container{
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 100%;
   padding: 20px;
}

.CertificateOptions-animal-gif{
   height: 150px;
   width: auto;
   object-fit: cover;
}


.CertificateOptions-error-container{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   padding: 20px;
}

.CertificateOptions-error--text{
   font-size: var(--fs-body);
   color: var(--color-red);
   /* color: #DC3D3D; */
   text-align: center;
}

.CertificateOptions-success-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.CertificateOptions-success--text{
   font-size: var(--fs-small);
   /* color: var(--color-text-secondary); */
   color: #5D5A6F;
   text-align: center;
}

.CertificateOptions-success--link{
   font-size: var(--fs-body);
   /* color: var(--color-text-primary); */
   color: #2C4B58;
   font-weight: 600;
   text-align: center;
}
   
