.questionIndicator__container {
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
     align-items: center;
    margin: 0 24px;
}

.questionIndicator__icon {
    color: var(--color-text-secondary);
    margin-right: 8px;
    stroke-width: 2px;
}

.questionIndicator__text--bold {
    color: var(--color-text-secondary);
    font-size: var(--fs-body);
    text-align: left;
    font-weight: 700;
    margin-right: 8px;
}

.questionIndicator__text {
    color: var(--color-text-secondary);
    font-size: var(--fs-body);
    text-align: center;
    width: auto;
}

