.OpportunitiesCardContainer-container{
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 100%;
}

.OpportunitiesCardContainer-header{
   font-size: var(--fs-body);
   text-align: left;
   color: var(--color-text-secondary);
}


.OpportunitiesCardContainer-grid-container{
   width: 100%;
   display: grid;
   grid-template-columns: repeat(
      auto-fill, minmax(230px, 1fr)
   );
   gap: 20px;
}