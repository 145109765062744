.GetCertificateModal--header{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
}

.GetCertificateModal--icon{
   display: flex;
   flex-shrink: 0;
   height: calc(var(--fs-body) * 3);
   width: calc(var(--fs-body) * 3);
   /* color: var(--color-red); */
}

.GetCertificateModal--title-container{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;
   justify-content: center;
   padding: 0 20px;
}

.GetCertificateModal--title{
   text-align: center;
   font-size: var(--fs-h4);
   /* color: var(--color-text-secondary); */
   color: #2C4B58;
   font-weight: 600;
}