.validateEmailFalse-father-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 32px 0;
  gap: 16px;
}

.validateEmailFalse-image{
  height: 100%;
  max-height: 150px;
  object-fit: contain;
}

.validateEmailFalse-title {
  font-size: var(--fs-h3);
  line-height: calc(var(--fs-h3) * 1.4);
}

.validateEmailFalse-text {
  font-size: var(--fs-h4);
  font-weight: 400;
  line-height: calc(var(--fs-h4) * 1.4);
}

.validateEmailFalse-secondaryText{
  font-size: var(--fs-small);
  line-height: calc(var(--fs-small) * 1.4);
}

.validateEmailFalse-contactArea-header{
  font-size: var(--fs-h4);
  line-height: calc(var(--fs-h4) * 1.4);
}

.validateEmailFalse-contactArea-style{
  width: 100%;
  max-width: 1000px;
}