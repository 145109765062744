.AboutProfessorCard-avatar-container{
   width: 100%;
   align-items: center;
}

.AboutProfessorCard-image-container{
   display: grid;
   place-items: center;
   border-radius: 50%;
}


.AboutProfessorCard--image{
   width: 50px;
   height: 50px;
   object-fit: cover;
   border-radius: 50%;
}

.AboutProfessorCard-avatar-professorInfo{
   display: flex;
   flex-direction: column;
   gap: 5px;
   align-items: flex-start;
   flex-grow: 1;
}

.AboutProfessorCard-avatar-professorInfo--name{
   font-size: var(--fs-small);
   font-weight: 600;
   color: var(--color-text-secondary);
}

.AboutProfessorCard-avatar-professorInfo--specialty{
   font-size: var(--fs-small);
   font-weight: 400;
   color: var(--color-text-primary);
}

.ClassDetails-card-container .splide__arrow--next {
   top: 0px;
   right: 0;
   height:60px;
   width:60px;
}
.ClassDetails-card-container .splide__arrow--prev{
   display: none;
}

.ClassDetails-card-container .splide__arrow--next svg{
   height: 20px;
   width: 20px;
}