.RegisterPage-pet-container{
   display: flex;
   align-items: center;
   gap: 20px;
}

.RegisterPage-pet-text{
   font-size: var(--fs-small);
}

.RegisterPage-pet-radios--container{
   display: flex;
   gap: 10px;
}

.RegisterPage-pet-radioGroup{
   position: relative;
   height: 40px;
   width: 40px;
   display: grid;
   place-items: center;
}

.pet-radio--input{
   position: absolute;
   height: 100%;
   width: 100%;
   cursor: pointer;
   opacity: 0;
   z-index: 1;
}

.pet-radio--icon{
   height: 100%;
   width: 100%;
   object-fit: cover;
   color: var(--color-primary-dark);
   border-radius: var(--border-radius-sm);
   padding: 4px;
   transition: opacity .3s;
}

.RegisterPage-pet-radioGroup:hover .pet-radio--icon{
   opacity: .7;
   transition: opacity .3s;
}

.pet-radio--input:checked~.pet-radio--icon{
   background-color: var(--color-base-light);
}

@media (max-width: 400px) {
   .RegisterPage-pet-container{
      flex-direction: column;
   }
   .RegisterPage-pet-text{
      text-align: center;
   }
}