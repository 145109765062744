.coursesClass-wrapper {
   /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
   overflow-y: scroll;
   height: 100%;
   padding: 16px 24px;
}

.coursesClass-nav-section{
   width: 100%;
   max-width: var(--maxWidthContainer);
   margin: 0 auto;
}

.coursesClass-container {
   display: flex;
   flex-direction: column;
   width: 100%;
   max-width: var(--maxWidthContainer);
   gap: 40px;
   margin: 32px 0;
}

.CoursesClass-error-container{
   display: flex;
   flex-direction: column;
   gap: 30px;
   max-width: var(--maxWidthContainer);
   width: 100%;
   margin: 0 auto;
   padding: var(--layout-padding-y) var(--layout-padding-x);
   font-size: var(--fs-h3);
}

@media (max-width: 1000px) {
   .coursesClass-nav-section{
      margin: 32px auto;
   }

   .coursesClass-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: var(--maxWidthContainer);
      gap: 40px;
   }
}