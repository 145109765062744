.LoginPage-container {
   min-height: 100vh;
   height: 100%;
   width: 100%;
   background-color: var(--color-base);
   background: linear-gradient(180deg, var(--color-base), var(--color-primary-dark));
   background-image: url('../../assets/fondo-web-forvet-cropped.svg');
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.LoginPage--component {
   overflow-x: hidden;
   position: relative;
   width: 100%;
   max-width: var(--maxWidthContainer);
   height: 100%;
   min-height: 100vh;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   box-shadow: var(--box-shadow-strong);
}

.LoginPage--component .decorative-empty-circle-container {
   filter: blur(3px);
}

.LoginPage-wrapper {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   padding: var(--full-layout-padding-y) var(--full-layout-padding-x);
}

.LoginPage-hero-container {
   width: 100%;
   flex-grow: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}

.LoginPage-hero-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 100%;
   width: 100%;
   flex-grow: 1;

   gap: 32px;

}

.LoginPage-hero-content {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 32px;
   z-index: 1;
}

.LoginPage-hero-link--mobile {
   display: none;
   
}

.LoginPage-hero-link--desktop {
   font-size: var(--fs-body);
   text-align: center;
   text-decoration: underline;
   color: var(--color-tertiary-dark);
}


.LoginPage-hero-title {
   text-align: center;
   font-size: 60px;
   color: var(--color-text-secondary);
   font-weight: 400;
}

.LoginPage-hero-title strong {
   color: var(--color-primary-light);
}

.LoginPage-hero-slogan {
   font-size: var(--fs-h3);
   text-align: center;
}

.LoginPage-browser-warning-container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 5px;
   background-color: var(--color-base-dark);
   border-radius: var(--border-radius-md);
   padding: 10px 0;
}

.LoginPage-browser-warning--text {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   text-align: center;
   text-wrap: balance;
}

.LoginPage-footer-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 16px;
   align-items: center;
   justify-content: flex-end;
   overflow: hidden;
}

.LoginPage-skeleton {
   margin-top: 48px;
}

.loginPage-modal-layout {
   display: flex;
   align-items: center;
   flex-direction: column;
   margin: 8px;
   gap: 16px;
}

.loginPage-modal-titleHeader {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   font-size: var(--fs-h4) !important;
}

.loginPage-modal-headerStyling {
   border-bottom: solid 1px black;
}

.loginPage-modal-body {
   font-size: var(--fs-small);
   align-items: calc(var(--fs-small)*1.3);
}

.loginPage-modal-img {
   width: 100%;
   max-width: 120px;
}

@media (max-width: 768px) {
   .LoginPage-wrapper {
      padding: var(--full-layout-padding-y) 5%;
   }

   .LoginPage-hero-link--mobile {
      display: block;
      font-size: var(--fs-body);
      text-align: center;
      text-decoration: underline;
      color: var(--color-tertiary-dark);
      opacity: .8;
   }

   .LoginPage-hero-link--desktop {
      display: none;
   }


   .LoginPage-hero-title {
      font-size: var(--fs-h1);
   }

   .LoginPage-hero-title {
      text-align: center;
   }
}