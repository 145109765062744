.ForoPage-no-accepted-terms--container{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 30px;

}

.ForoPage-no-accepted-terms--text{
   font-size: var(--fs-h4);
   text-align: center;
}

.ForoPage-no-accepted-terms--button{
   background-color: transparent;
   border: none;
   outline: none;
   text-decoration: underline;
   cursor: pointer;
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
}

.ForoPage-no-accepted-terms--group{
   display: flex;
   flex-direction: column;
   gap: 5px;
   align-items: center;
}

.ForoPage-no-accepted-terms--button:hover{
   opacity: .7;
}