.dasboardRemindCard-father-div {
  display: flex;
  width: 100%;
  margin-top: 24px;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow);
  color: var(--color-text-secondary);
  overflow: hidden;
}

.dasboardRemindCard-cardColor {
  width: 100%;
  max-width: 4%;
  background-color: var(--color-primary);
}

.dasboardRemindCard-generalInformation {
  display: flex;
  gap: 8px;
  padding: 16px 16px 16px 0;
}

.dasboardRemindCard-generalInformation-icon {
  width: 100%;
  max-width: 40px;
}

.dasboardRemindCard-generalInformation-wrapper {
  display: flex;
  flex-direction: column;
}

.dasboardRemindCard-generalInformation-title {
  font-size: var(--fs-body);
}

.dasboardRemindCard-generalInformation-text {
  font-size: var(--fs-small);
  word-break: break-word;
}

.dasboardRemindCard-extraInformation-divSpan {
  display: flex;
  align-items: center;
}

.dasboardRemindCard-extraInformation-span {
  color: var(--color-tertiary-light);
  font-size: var(--fs-small);
}

.dasboardRemindCard-extraInformation-text {
  font-size: var(--fs-small);
  margin-left: 8px;
}
