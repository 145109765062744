.mainLayout__container {
   height: 100%;
   width: 100%;
   display: grid;
   grid-template-columns: var(--sidebar-width) 1fr;
   grid-template-rows: auto;
   transition: var(--sidebar-transition);

   max-width: var(--maxWidthContainer);
   box-shadow: var(--box-shadow-strong);

   background-image: url('../../assets/fondo-web-forvet-cropped.svg');
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.mainLayout__container--collapsed {
   display: grid;
   grid-template-columns: var(--sidebar-width-collapsed) 1fr;
   grid-template-rows: auto;
   transition: var(--sidebar-transition);

   max-width: var(--maxWidthContainer);
   box-shadow: var(--box-shadow-strong);
}

.mainLayout__aside {
   width: 100%;
   position: relative;
}

.mainLayout__main {
   width: 100%;
   /*min-width: calc(var(--maxWidthContainer) - var(--sidebar-width));*/
   min-height: 100vh;
   height: 100vh;
   max-height: 100%;
}

@media (min-width: 769px) and (max-width: 1000px) {
   .mainLayout__container {
      display: flex;
      flex-direction: column;
   }
   .mainLayout__aside {
      /*position: fixed;*/
      z-index: 100;
   }
   .mainLayout__main {
      padding-top: var(--headerHeight);
   }
}


@media screen and (max-width: 768px) {
   .mainLayout__container {
      display: flex;
      flex-direction: column;
   }
   .mainLayout__aside {
      /*position: fixed;*/
      z-index: 100;
   }
   .mainLayout__main {
      padding-top: var(--headerHeight);
   }
}