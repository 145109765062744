
.aval-image-wrapper {
   position: relative;
   top: 0;
   left: 0;
   width: 120px;
   height: 120px;
   margin: 0 auto;
}

.aval-image-container {
   height: 100%;
   width: 100%;
   display: grid;
   place-items: center;
}

.aval-image {
   height: 100%;
   max-height: 90px;
   width: auto;
   object-fit: cover;
   filter: grayscale(100%);
   transition: all .3s;
   cursor: pointer;
}

.aval-image:hover {
   filter: grayscale(0%);
   transition: all .3s;
}

@media (max-width: 768px) {
   .aval-image {
       max-height: 64px;
   }
   .aval-image-container {
       display: grid;
       place-items: center;
   }
}