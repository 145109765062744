.ForumRichText-container{
   display: flex;
   flex-direction: column;
   gap: 30px;

}
.ForumRichText-footer-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
   align-items: flex-start;
}

.ForumRichText--fileName{
   font-size: var(--fs-small);
   color: var(--color-text-primary);
}