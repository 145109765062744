.ForoCard-container{
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding: 30px;
   background-color: var(--color-base-light);
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
   /*TODO: Review after merge issue*/
   /* background-color: var(--color-base-light); */
   /*overflow: hidden;*/
}