.examScore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /*This is a must for every page layout without MainLayout on it*/
    max-width: var(--maxWidthContainer);
    box-shadow: var(--box-shadow-strong);
    /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
    overflow-y: scroll;
    height: 100%;
    min-height: 100vh;
    padding: 16px 24px;
}

.examScore-HeaderNavigation{
    width: 100%;
    margin: 16px 0 32px;
}

.examScore-mainContent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}