.noContentMessage__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    width: 80%;
    align-self: center;
}

.noContentMessage__wrapper {
    text-align: center;
    padding: 32px;
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-md);
    background-color: var(--color-base-light);
}

.noContentMessage__img {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
}

.noContentMessage__title {
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3) * 1.1);
    color: var(--color-text-secondary);
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
}

.noContentMessage__message {
    font-size: var(--fs-body);
    line-height: calc(var(--fs-body) * 1.2);
    color: var(--color-text-secondary);
    font-weight: 500;
    text-align: center;
}
