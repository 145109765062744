.questionnaire__wrapper {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

.questionnaire__container {
   width: 100%;
   height: 100%;
   display: grid;
   grid-template-columns: 9fr 3fr;
   grid-template-rows: 1fr;
   gap: 32px;
}

.questionnaire__title {
   width: 100%;
   height: auto;
   color: var(--color-text-secondary);
   font-size: var(--fs-h1);
   line-height: var(--fs-h1);
   margin-bottom: 32px;
   text-align: center;
}

.questionnaire__indicatorsContainer {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 80%;
   height: 100%;
   margin: 32px 0;
}

.questionnaire__questionContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
   height: 100%;
   padding: 0 24px;
}

.questionnaire__questionSelector__wrapper {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   padding: 0 24px;
}

.questionnaire__questionSelector__title {
   color: var(--color-text-secondary);
   font-size: var(--fs-body);
   font-weight: 700;
   margin-bottom: 16px;
   text-align: center;
   max-width: 100%;
}


.questionnaire__nextButton {
   margin-top: 24px;
   align-self: center;
}

@media (max-width: 668px) {
   .questionnaire__wrapper {
      overflow-x: hidden;
   }

   .questionnaire__container {
      display: flex;
      flex-direction: column;
   }

   .questionnaire__questionSelector__wrapper {
      order: -1;
      margin-top: 32px;
      padding: 0;
   }

   .questionnaire__indicatorsContainer {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin: 0;
   }

   .questionnaire__title {
      display: flex;
      color: var(--color-text-secondary);
      font-size: var(--fs-h2);
      line-height: var(--fs-h2);
      text-wrap: balance;
      max-width: 100%;
   }

   .questionnaire__questionContainer{
      padding: 0;
   }

}