.ForumSkeletonCard-container {
   display: flex;
   flex-direction: column;
   gap: 30px;
   background-color: #0000001a;
   padding: 20px;
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
}

.ForumSkeletonCard-header {
   display: flex;
   align-items: center;
   gap: 10px;
}

.ForumSkeletonCard-content {
   display: flex;
   flex-direction: column;
   gap: 20px;

}

.ForumSkeletonCard--text {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.ForumSkeletonCard--footer {
   width: 100%;
}



.ForumSkeletonCard--width-1 {
   width: 60%;
}

.ForumSkeletonCard--width-2 {
   width: 50%;
}

.ForumSkeletonCard--width-3 {
   width: 55%;
}

@media (width < 1000px) {
   .ForumSkeletonCard--width-1 {
      width: 90%;
   }

   .ForumSkeletonCard--width-2 {
      width: 80%;
   }

   .ForumSkeletonCard--width-3 {
      width: 85%;
   }
}