.RateFaceButton-container {
   position: relative;
   background-color: transparent;
   border: none;
   outline: none;
}

.RateFaceButton-container:hover {
   opacity: .8;
}

.RateFaceButton--image {
   height: 100px;
   width: 100px;
   object-fit: cover;
   border-radius: 50%;
}

.RateFaceButton--input {
   position: absolute;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   opacity: 0;
   cursor: pointer;
}

.RateFaceButton--input:checked~.RateFaceButton--image {
   outline: 4px solid #77BDE0;
   transform: scale(1.3);
}

.RateFaceButton--label{
   font-size: var(--fs-body);
   text-align: center;
   /* color: var(--color-text-primary); */
   color: #5D5A6F;
}

.RateFaceButton--column{
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;
}

@media (max-width: 768px) {
   .RateFaceButton-container{
      width: 30%;
   }
   .RateFaceButton--image {
      height: auto;
      width: 100%;
   }

   .RateFaceButton--input {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0;
      cursor: pointer;
   }
}