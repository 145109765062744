.sponsor-products-container {
  display: grid;
  grid-template-columns: 40% auto;
  width: 100%;
  height: auto;
  gap: 24px;
}

.sponsor-products-banner{
width: 100%;
}

.sponsor-products-carousel{
width: 100%;
}

@media (max-width: 768px) {
.sponsor-products-container {
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 16px; 
  height: 100%;
}

.sponsor-products-banner,
.sponsor-products-carousel {
  width: 100%; 
}
}
