.questionNavigator__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, auto));
    width: 100%;
    gap: 8px;
    place-items: center;
}

.questionNavigator__questionSelector {
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-sm);
    font-size: var(--fs-body);
    line-height: var(--fs-body);
    cursor: pointer;
    transition: all .3s;

    background-color: var(--color-primary-lightest);
    color: var(--color-text-secondary);
}

.questionNavigator__questionSelector--active {
    background-color: var(--color-primary-dark);
    color: var(--color-text-tertiary);
}

@media (max-width: 668px) {
   .questionNavigator__container{
      display: flex;
      overflow-x: scroll;
      flex-wrap: nowrap;
      width: 100%;
   }
   .questionNavigator__questionSelector{
      flex-shrink: 0;
   }
}