.sidebar-footer-container{
   display: flex;
   flex-grow: 1;
   flex-direction: column;
   justify-content: flex-end;
}

.sidebar-footer-container .sidebar-navLink-container {
   background-color: inherit;
   outline: none;
   border: none;
   cursor: pointer;
}

.sidebar-footer-container .sidebar-navLink-container:hover {
   background-color: var(--color-primary-light);
}
/* 
@media (max-height: 700px) {
   .sidebar-footer-container {
      flex-grow: unset;
   }
} */

@media (max-width: 768px) {
   .sidebar-footer-container{
      width: 100%;
      flex-grow: 0;
   }
}