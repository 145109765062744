

.ConfirmExamModal--title{
   font-size: var(--fs-h3) !important;
   text-align: center;
   width: 100%;
}

.ConfirmExamModal-container{
   font-size: var(--fs-body);
   display: flex;
   flex-direction: column;
}

.ConfirmExamModal-container p:first-child{
   font-size: var(--fs-small);
}

.ConfirmExamModal-container p {
   margin-top: 8px;
   text-align: center;
}

.ConfirmExamModal-button-container{
   margin-top: 32px;
   display: flex;
   justify-content: center;
   width: 100%;
   gap: 20px;
}

@media (max-width: 768px) {
   .ConfirmExamModal-button-container{
      flex-direction: column;
   }
}

