.sidebar-header-container {
   display: flex;
   flex-direction: column;
   transition: var(--sidebar-transition);
   height: auto;
   max-height: 240px;
   gap: 24px;
}

.sidebar-logo-container {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   transition: var(--sidebar-transition);
}

.sidebar-logo-image {
   max-height: 32px;
   transition: var(--sidebar-transition);
   padding-top: 8px;
}

.sidebar-user-info-container {
   display: flex;
   flex-direction: column;
   gap: 16px;
   align-items: center;
}

.sidebar-avatar-container {
   z-index: 1;
   height: 88px;
   width: 88px;
   border-radius: 50%;
   outline: 4px solid var(--color-base-light);
   transition: var(--sidebar-transition);

}

.sidebar-avatar-image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 50%;
   transition: var(--sidebar-transition);

}

.sidebar-user-name {
   color: var(--color-base-light);
   font-size: var(--fs-h4);
   line-height: calc(var(--fs-h4) * 1.1);
   font-weight: 400;
   font-family: var(--font-primary);
   transition: var(--sidebar-transition);
   text-align: center;
}


@media screen and (min-width: 1201px) and (max-width: 1400px) {
   .sidebar-header-container {
      max-height: 200px;
   }
   .sidebar-user-info-container {
      gap: 16px;
   }
   .sidebar-avatar-container {
      border-radius: 50%;
   }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
   .sidebar-user-info-container {
      gap: 16px;
   }
   .sidebar-avatar-container {
      height: 64px;
      width: 64px;
   }
   .sidebar-user-name {
      font-size: calc(var(--fs-h3) * 0.7);
      line-height: calc(var(--fs-h3) * 0.8);
   }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
   .sidebar-header-container {
      gap: 16px;
   }
   .sidebar-user-info-container {
      gap: 16px;
   }
   .sidebar-avatar-container {
      height: 72px;
      width: 72px;
   }
   .sidebar-user-name {
      font-size: calc(var(--fs-h3));
      line-height: calc(var(--fs-h3) * 1.2);
   }
}

@media (max-height: 950px) and (min-width: 768px) {
   .sidebar-header-container{
      height: max-content;
   }
   /*.sidebar-logo-container, .sidebar-user-name{*/
   /*   display: none;*/
   /*}*/
}

/*@media (max-height: 768px) and (min-width: 768px) {*/
/*   .sidebar-header-container{*/
/*      display: none;*/
/*   }*/
/*}*/

@media (max-width:768px) {
   .sidebar-logo-image{
      max-height: 32px;
   }

   .sidebar-avatar-container {
      height: 72px;
      width: 72px;
   }
}