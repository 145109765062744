.RegisterPage-form-container{
   width: 100%;
   gap: 20px;
   display: flex;
   flex-direction: column;
   z-index: 1;
}

.RegisterPage-form-container input{
   background-color: var(--color-base-light);
   color: var(--color-text-secondary);
   border:none;
}


.RegisterPage-form-container input::placeholder{
   color: var(--color-text-primary);
}

.RegisterPage-form-container label{
   color: var(--color-text-secondary);
   font-weight: 600;
}

.RegisterPage-avatar-group{
   display: grid;
   grid-template-areas: "avatar userInfo"
                        "inputFile inputFile";
   grid-template-columns: 200px 1fr;
   gap: 10px;
}

.RegisterPage-avatar-container{
   grid-area: avatar;
   height: 100%;
   width: 100%;
   display: grid;
   place-items: center;
}

.RegisterPage-avatar-image{
   height: 100%;
   width: 100%;
   max-height: 150px;
   /* max-width: 150px; */
   object-fit: cover;
   border-radius:var(--border-radius-sm);
   z-index: 1;
}

.RegisterPage-avatar-userInfo-container{
   grid-area: userInfo;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.RegisterPage-avatar-userInfo--completeName{
   font-size: var(--fs-h3);
   text-transform: capitalize;
   color: var(--color-text-secondary);
}

.RegisterPage-avatar-userInfo--type{
   font-size: var(--fs-body);
   color: var(--color-text-primary);
   text-transform: capitalize ;
}

.RegisterPage-avatar--input{
   grid-area: inputFile;
   background-color: transparent !important;
}

.RegisterFormPage--textarea{
   background-color: var(--color-base-light) !important;
   border: none !important;
   color: var(--color-text-secondary) !important;
}

.RegisterFormPage--textarea::placeholder{
   color: var(--color-text-primary) !important;
}

.RegisterPageForm-password--input{
   width: 100%;
   border: none !important;
}

.RegisterPage-passwords-text{
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
}

.RegisterPage-passwords-text-clickable {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
   text-decoration: underline;
   font-weight: 400;
   cursor: pointer;
   text-align: center;
   width: 100%;
}

.dniExamplesModal--title{
   font-size: var(--fs-h4);
   color: var(--color-text-primary);
   margin-bottom: 8px;
}

.dniExamplesModal--countryColor{
   border-radius: 50%; 
   height: 16px;
   width: 16px;
}

.dniExamplesModal--countryName, .dniExamplesModal--countryDni{
   font-size: var(--fs-body);
}

.dniExamplesModal--countryDni{
   opacity: .8;
}

@media (max-width: 768px) {
   .RegisterPage-avatar-group{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }
   .RegisterPage-avatar-container{
      order: 1;
   }

   .RegisterPage-avatar-image{
      max-width: 200px;
   }

   .RegisterPage-avatar-userInfo-container{
      order: 0;
      width: 100%;
      justify-content: center;
      text-align: center;
   }
   .RegisterPage-avatar--input{
      order: 2;
      padding: 10px 0;
      margin: 0 auto;
   }
}

