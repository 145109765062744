.PrevExamPage-infoCard-container{
   position: relative;
   display: flex;
   flex-direction: column;
   gap: 15px;
   background-color: var(--color-base-light);
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
   padding: 20px;
   overflow: hidden;
}

.PrevExamPage-infoCard--content{
   display: flex;
   flex-direction: column;
   gap: 20px;
   z-index: 1;
}

.PrevExamPage-infoCard-header{
   display: flex;
   gap: 20px;
   align-items: center;
}

.PrevExam-icon-container{
   padding: 10px 12px;
   border-radius: var(--border-radius-sm);
   background-color: var(--color-primary-lightest-transparent);
}

.PrevExam-headerTitle{
   text-align: center;
   font-size: var(--fs-h3);
}

.PrevExamPage-infoCard-introduction{
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.PrevExamPage-infoCard-introduction h5{
   font-size: var(--fs-body);
   line-height: normal;
}

.PrevExamPage-infoCard-introduction-textGroup{
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.PrevExamPage-infoCard-introduction-textGroup li{
   font-size: var(--fs-small);
   line-height: normal;
   max-width: 80ch;
   margin-left: 40px;
}

.PrevExamPage-infoCard-footer{
   display: flex;
   width: 100%;
   align-items: center;
   z-index: 1;
}

@media (max-width: 576px) {
   .PrevExamPage-infoCard-header{
      justify-content: center;
      flex-direction: column;
   }

   .PrevExamPage-infoCard-introduction{
      align-items: center;
   }

   .PrevExamPage-infoCard-introduction p {
      text-align: center;
   }
   
   .PrevExamPage-infoCard-footer{
      justify-content: center;
   }
}