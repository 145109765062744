.RateClassBanner-container{
   position: relative;
   overflow: hidden;
   width: 100%;
   /* max-width: 750px; */
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   gap: 20px;
   align-items: center;

   padding: 15px;
   background-color: var(--color-base-light);
   font-family: var(--font-primary);
   border-radius: var(--border-radius-sm);
   box-shadow: var(--box-shadow);
}

.RateClassBanner-rate-container{
   display: flex;
   flex-direction: column;
   gap: 30px;
   z-index: 1;
}

.RateClassBanner-rate-content{
   display: flex;
   gap: 40px;
}

.RateClassBanner-text-container p:first-child{
   text-align: center;
   color: var(--color-secondary);
   font-size: var(--fs-small);
   background-color: var(--color-secondary-transparent);
   border-radius: var(--border-radius-sm);
   padding: 10px;
   font-weight: 600;
   z-index: 1;
}

.RateClassBanner-text-container p:last-child{
   text-align: center;
   color: var(--color-secondary);
   font-size: var(--fs-h3);
   font-weight: 600;
}

.RateClassBanner-notification-error-container{
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
}

.RateClassBanner-notification-error--text{
   text-align: center;
   font-size: var(--fs-body);
   color: var(--color-white);
   background-color: var(--color-red);
   padding: 10px 20px;
   border-radius: var(--border-radius-sm);
}

@media (max-width: 768px) {
   .RateClassBanner-rate-content{
      gap: 20px;
   }
}