.validateEmailTrue-father-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 16px;
  padding: 64px 0;
}

.validateEmailTrue-image {
  height: 100%;
  max-height: 150px;
  object-fit: contain;
}

.validateEmailTrue-firstTitle{
  font-size: var(--fs-h2);
  line-height: calc(var(--fs-h2) * 1.4);
}

.validateEmailTrue-secondTitle {
  font-size: var(--fs-h3);
  line-height: calc(var(--fs-h3) * 1.4);
}

.validateEmailTrue-text {
  font-size: var(--fs-body);
  line-height: calc(var(--fs-body) * 1.4);
}
