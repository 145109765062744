.OpportunitiesCard-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   padding: 20px;
   justify-content: space-between;
   align-items: center;
   background-color: var(--color-base-light);
   border-radius: var(--border-radius-md);
   box-shadow: var(--box-shadow);
   color: var(--color-text-secondary);
   gap: 20px;
}

.OpportunitiesCard-logo-container{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 125px;
   width: 125px;
   border-radius: var(--border-radius-md);
}

.OpportunitiesCard--logo{
   height: 100%;
   width: 100%;
   object-fit: cover;
   border-radius: var(--border-radius-md);
}

.OpportunitiesCard-texts-container{
   flex-grow: 1;
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-items: center;
   font-size: var(--fs-body);
}


.OpportunitiesCard--title{
   font-weight: 700;
   text-align: center;
   color: var(--color-text-secondary);
}

.OpportunitiesCard--description{
   font-size: var(--fs-small);
   text-align: center;
   text-overflow: ellipsis;
   text-wrap: balance;
   flex-grow: 1;
   color: var(--color-text-secondary);
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 3;
   overflow: hidden;
}


.OpportunitiesCard--link{
   font-size: var(--fs-small);
   text-align: center;
   color: var(--color-tertiary);
   text-decoration: underline;
   cursor: pointer;
   border: none;
   outline: none;
   background-color: transparent;
}