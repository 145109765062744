.OpportunitiesPage-wrapper {
   /*background-color: #0a570a;*/
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   gap: 24px;
   max-width: 100%;

   /*This is a must for every page layout to avoid multiple scrollbars & undesired overflow*/
   overflow-y: scroll;
   height: 100%;
   padding: 16px 24px;
}

.OpportunitiesPage-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 32px;
}

.OpportunitiesPage-header{
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.OpportunitiesPage-header .coursesClassHeaderNavigation-title-styling{
   font-size: var(--fs-h1);
   color: var(--color-text-secondary);
}

.OpportunitiesPage-error-container{
   display: flex;
   flex-direction: column;
   gap: 40px;
   align-items: center;
}

.OpportunitiesPage-error--text{
   color: var(--color-red);
   font-size: var(--fs-h3);
   text-wrap: balance;
   text-align: center;
}

.OpportunitiesPage-notResults-container{
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 5px;
   align-items: center;
   justify-content: center;
}

.OpportunitiesPage-notResults--icon{
   color: var(--color-text-secondary);
}

.OpportunitiesPage-notResults--text{
   font-size: var(--fs-h3);
   color: var(--color-text-primary);
   text-wrap: balance;
   text-align: center;
}