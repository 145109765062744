@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');


:root {
    /*PADDING & MARGIN & SIZES*/
    --px: 80px;
    --py: 60px;
    --mt: 20px;
    --mb: 20px;
    --layout-padding-x: 48px;
    --layout-padding-y: 32px;
    --full-layout-padding-x: 48px;
    --full-layout-padding-y: 16px;

    --box-shadow: 0px 6px 16px 0px #0000000F;
    --box-shadow-md: 1px 1px 4px #000000b6;
    --box-shadow-strong: 0px 0px 6px #00000063;
    --maxWidthContainer: 1600px;
    --font-primary: 'Montserrat', sans-serif;

    /*COMPONENTS*/
    --headerHeight: 100px;
    --sidebar-width: 400px;
    --sidebar-width-collapsed: 104px;
    --sidebar-transition: all 1s ease-in-out;
    --calendar-width: 352px;
    --calendar-width-collapsed: 150px;

    /*TODO: REPLACE THIS*/
    --gray: #868686;
    --gray-normal: #505050;
    --gray-1: #DEE2E6;
    --blue-gray: #F1F5F9;

    /*BORDER RADIUS*/
    --border-radius-lg: 1.5rem;
    --border-radius-md: 1rem;
    --border-radius-sm: 0.625rem;

    /*FONT-SIZES HEADING*/
    --fs-h1: 40px;
    --fs-h2: 32px;
    --fs-h3: 24px;
    --fs-h4: 20px;
    --fs-h5: 18px;
    --fs-h6: 16px;
    /*FONT-SIZES BODY*/
    --fs-body: 16px;
    --fs-small: 14px;
    --fs-tiny: 12px;

    --color-dark-blue: #0A033C;
    --color-white: #FFF;

}

.dark-theme {
   /*COLORS*/
   --color-primary-lightest: #508a96;
   --color-primary-light: #416b74;
   --color-primary-dark: #274c53;
   --color-primary-darkest: #132f35;
   --color-primary: #005163;
   --color-primary-lightest-transparent: #508a9634;


    --color-secondary-light: #A3D2EA;
    --color-secondary: #64C0E4;
    --color-secondary-dark: #5fbde1;
    --color-secondary-transparent: #a3d2ea2a;

    --color-tertiary-light: #ec9cb9;
    --color-tertiary: #FF7BAC;
    --color-tertiary-dark: #f33a7e;

    --color-base-light: #FFFFFF;
    --color-base: #F5F5F5;
    --color-base-dark: #DADADA;

    --color-text-primary: #5D5A6F;
    --color-text-secondary: #005163;
    --color-text-tertiary: #FFF;

    --color-white: #FFF;
    --color-black: #212121;
    --color-black-transparent: rgba(0,0,0,0.5);

    --color-red: #DC3D3D;
    --color-yellow: #FFCB47;

    --color-red--light: #facbbc;
    --color-yellow--light: #FFF8B8;
    --color-green--light: #CAF2C2;

}

.light-theme {
    /*COLORS*/
    --color-primary-lightest: #bcd8dd;
    --color-primary-light: #416b74;
    --color-primary: #005163;
    --color-primary-dark: #274c53;
    --color-primary-darkest: #132f35;
    --color-primary-lightest-transparent: #77bee128;

    --color-secondary-light: #A3D2EA;
    --color-secondary: #64C0E4;
    --color-secondary-dark: #5fbde1;
    --color-secondary-transparent: #77BDE01A;

    --color-tertiary-light: #e1568b;
    --color-tertiary: #FF7BAC;
    --color-tertiary-dark: #da1962;

    --color-base-light: #FFFFFF;
    --color-base: #F5F5F5;
    --color-base-dark: #DADADA;

    --color-text-primary: #5D5A6F;
    --color-text-secondary: #005163;
    --color-text-tertiary: #FFF;

    --color-white: #FFF;
    --color-black: #212121;
    --color-black-transparent: rgba(0,0,0,0.5);

    --color-red: #DC3D3D;
    --color-yellow: #FFCB47;

    --color-red--light: #facbbc;
    --color-yellow--light: #FFF8B8;
    --color-green--light: #CAF2C2;

/*    DEFAULT OPTIONS   */
    background-color: var(--color-base);
    color: var(--color-text-primary);
    font-family: var(--font-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

hr {
    width: 100%;
    border: 1px solid var(--color-primary-light);
    margin: 16px 0;
}

/* para el onboarding */
.OnBoarding--text-title {
   font-size: var(--fs-h2);
   color: var(--color-text-primary);
}

.OnBoarding--text-body {
   color: var(--color-text-secondary);
   font-size: var(--fs-body);
   max-width: 50ch;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    :root {
        --px: 40px;
        /*BORDER RADIUS*/
        --border-radius-lg: 1.5rem;
        --border-radius-md: 1rem;
        --border-radius-sm: 0.8rem;

        /*COMPONENTS*/
        --headerHeight: 100px;
        --sidebar-width: 304px;
        --sidebar-width-collapsed: 104px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
    :root {
        --px: 40px;
        /*BORDER RADIUS*/
        --border-radius-lg: 1.5rem;
        --border-radius-md: 1rem;
        --border-radius-sm: 0.8rem;

        /*COMPONENTS*/
        --headerHeight: 100px;
        --sidebar-width: 264px;
        --sidebar-width-collapsed: 104px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    :root {
        --px: 40px;
        /*BORDER RADIUS*/
        --border-radius-lg: 1.5rem;
        --border-radius-md: 1rem;
        --border-radius-sm: 0.8rem;

        /*COMPONENTS*/
        --headerHeight: 100px;
        --sidebar-width: 304px;
        --sidebar-width-collapsed: 104px;
    }
}

@media screen and (max-width: 768px) {
    :root {
        --headerHeight: 80px;
        --px: 10%;
        --py: 32px;
        --layout-padding-x: 24px;
        --layout-padding-y: 16px;

        /*BORDER RADIUS*/
        --border-radius-lg: 1.5rem;
        --border-radius-md: 1rem;
        --border-radius-sm: 0.8rem;

      /*FONT-SIZES HEADING*/
      --fs-h1: 40px;
      --fs-h2: 32px;
      --fs-h3: 22px;
      --fs-h4: 20px;
      --fs-h5: 18px;
      --fs-h6: 16px;
      /*FONT-SIZES BODY*/
      --fs-body: 16px;
      --fs-small: 14px;
      --fs-tiny: 12px;
   }

   .OnBoarding--text-title {
      text-align: center;
   }

   .OnBoarding--text-body {
      text-align: center;
      max-width: unset;
   }
}
