.CalendarRow-container {
   display: grid;
   grid-template-columns: 25px 2px 1fr;
   grid-template-areas:
      "number separator name"
      "number separator event"
      "number separator auName"
   ;
   column-gap: 10px;
}

.CalendarRow--numberArea {
   grid-area: number;
   display: flex;
   align-items: center;
}



.CalendarRow--numberText {
   font-size: var(--fs-body);
   color: var(--color-text-secondary);
   font-weight: 600;
}

.CalendarRow--eventArea {
   grid-area: event;
   display: flex;
   align-items: center;
   gap: 5px;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
}


.CalendarRow--eventText {
   font-size: var(--fs-small);
   color: var(--color-text-primary);
   font-weight: 400;
   text-transform: capitalize;
}

.CalendarRow--nameArea {
   grid-area: name;
   overflow: hidden;
}

.CalendarRow--nameText {
   font-size: var(--fs-small);
   color: var(--color-text-secondary);
   font-weight: 400;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.CalendarRow--auName {
   grid-area: auName;
   overflow: hidden;
   display: grid;
    place-items: flex-start;
}

.CalendarRow--auName--text {
   font-size: var(--fs-small);
   color: var(--color-text-secondary);
   font-weight: 400;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   font-style: italic;
}

.CalendarRow-separatorLine {
   grid-area: separator;
}

.CalendarRow-lesson {
   background-color: var(--color-tertiary);
}

.CalendarRow-exam {
   background-color: var(--color-secondary);
}

.CalendarRow-reExam {
   background-color: var(--color-yellow);
}

.CalendarRow-general{
   background-color: var(--color-red);
}

.CalendarRow-break{
   background-color: var(--color-primary);
}