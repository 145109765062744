.ForoCardFooter-container{
   width: 100%;
   display: flex;
}

.ForoCardFooter--link{
   font-size: var(--fs-body);
   color: var(--color-red);
   text-decoration: underline;
   cursor: pointer;
}