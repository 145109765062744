.UserProfile-form-container {
    width: 100%;
    /* max-width: 500px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: form;
}

.UserProfile-form-container .pet-radio--icon {
    color: var(--color-text-primary);
}

.UserProfile-form-container .pet-radio--input:checked ~ .pet-radio--icon {
    color: var(--color-white);
    background-color: var(--color-text-secondary);
}

.UserProfile-form-container .RegisterPage-avatar-group {
    align-items: flex-start;
}

.UserProfile-form-container .RegisterPage-avatar-group .RegisterPage-avatar-container {
    display: flex;
    justify-self: flex-start;
}

.UserProfile-form-container .RegisterPage-avatar-userInfo--completeName {
    font-size: var(--fs-h3);
    text-transform: capitalize;
    color: var(--color-text-secondary);
}

.UserProfile-form-container .RegisterPage-avatar-userInfo--type {
    font-size: var(--fs-body);
    color: var(--color-text-tertiary);
    text-transform: uppercase;
    width: auto;
    align-self: flex-start;

    padding: 6px 12px;
    background-color: var(--color-tertiary);
    border-radius: var(--border-radius-sm);
}

.UserProfileForm-rowInputGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}

.UserProfile-form-container .RegisterPage-passwords-text {
    color: var(--color-text-secondary);
}

.UserProfileForm-changePass {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;
}

@media (max-width: 768px) {
    .UserProfileForm-rowInputGroup {
        flex-direction: column;
    }

    .RegisterPage-avatar-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .UserProfile-form-container .RegisterPage-avatar-container {
        justify-content: flex-start;
    }

    .UserProfile-form-container .RegisterPage-avatar-userInfo-container {
        justify-content: flex-start;
        text-align: left;
    }

    .UserProfile-form-container .RegisterPage-avatar--input {
        justify-self: flex-start;
        width: 100%;
    }
}