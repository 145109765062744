.PrevExamPage-infoCard--iconGroup{
   display: flex;
   align-items: center;
   gap: 10px;
}

.PrevExamPage-infoCard--icon{
   color: var(--color-tertiary);
}

.PrevExamPage--borderIcon{
   outline: 1px solid var(--color-tertiary);
   border-radius: 4px;
}

.PrevExamPage-infoCard--iconGroup h5{
   font-size: var(--fs-body);
}

.PrevExamPage-infoCard--iconGroup span{
   font-size: var(--fs-small);
}

@media (max-width: 576px) {
   .PrevExamPage-infoCard--iconGroup{
      flex-direction: column;
   }
   .PrevExamPage-infoCard--iconGroup h5{
      text-align: center;
   }
}