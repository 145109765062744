.coursesHeader-father-div {
    display: flex;
    width: 100%;
}

.coursesHeader-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-h2);
    line-height: calc(var(--fs-h2) * 1.2);
    color: var(--color-text-secondary);
}

.coursesHeader-iframe {
    width: 100%;
    max-width: 40%;
    margin-top: 16px;
    border: 0;
    border-radius: 8px;
}

.coursesHeader-image {
    width: 100%;
    max-width: 40%;
    margin-top: 16px;
    margin-left: auto;
    border-radius: 8px;
    object-fit: cover;
}


@media (max-width: 768px) {
    .coursesHeader-father-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .coursesHeader-title {
        width: 100%;
        text-align: center;
    }
    .coursesHeader-iframe {
        width: 100%;
        max-width: 100%;
        margin-top: 16px;
    }

    .coursesHeader-image {
        margin-left: 0;
    }
}