.helpHeader-father-div{
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: var(--color-primary-lightest);
    border-radius: var(--border-radius-sm);
    padding: 24px;
}
/* if is tablet this class will render */
.helpHeader-father-div-tablet{
    padding: 8px;
}

.helpHeader-titles-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 2;
}

.helpHeader-title{
    font-size: var(--fs-h2);
    color: var(--color-text-secondary);
}

.helpHeader-subtitle{
    font-size: var(--fs-h4);
    color: var(--color-text-secondary);
}

.helpHeader-image{
    display: flex;
    align-items: flex-start;
    position: absolute;
    z-index: 1;
}

.helpHeader-selector{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 24px 0;
    z-index: 2;
    gap: 8px;
}
/* if mobile this class will render */
.helpHeader-selector-mobile{
    flex-direction: column;
    padding: 16px 0;
}

.helpHeader-selectRoot{
    width: 60%;
}